import styled from "styled-components";
import { colors } from "../../utils/styles/theme";
import { EASE_FUNC } from "../../utils/styles/animations";
import { mediaDesktop, mediaDesktopLarge } from "../../utils/styles/responsive";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const Button = styled.button`
  width: 100%;
  height: 100%;
  background-color: ${colors.electricViolet};
  border-bottom: 4rem solid ${colors.brightTurquoise};

  font-family: Archivo;
  font-size: 14rem;
  color: ${colors.white};
  padding: 5rem 20rem;

  transition: background-color 0.3s ${EASE_FUNC.easeInOut},
    color 0.3s 0.1s ${EASE_FUNC.easeInOut},
    border-bottom 0.3s ${EASE_FUNC.easeInOut};

  ${mediaDesktop(`
    font-size: 28rem;
    &:hover {
      
    border-bottom: 4rem solid ${colors.electricViolet};
      background-color: ${colors.brightTurquoise};
      color: ${colors.electricViolet};
    }
  `)}
  ${mediaDesktopLarge(`
    font-size: 24rem;
  `)}
`;
