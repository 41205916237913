import {
  Group,
  Object3D,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
} from "three";

import { parseUrlQuery } from "../../../utils/url";

interface BaseScene {
  update?(timestamp: number): void;
}

abstract class BaseScene {
  scene = new Scene();
  camera = new PerspectiveCamera();
  renderer: WebGLRenderer | undefined;
  container: Object3D | undefined;

  public transitionGroup = new Group();

  abstract start: () => void;
  abstract pause: () => void;
  abstract resume: () => void;
  abstract stop: () => void;
  abstract animate: () => void;

  debugMode: boolean;

  protected constructor(readonly canvas: HTMLCanvasElement) {
    this.debugMode = parseUrlQuery().debugScene === "true";
  }

  resize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.renderer?.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }

  render = () => {
    if (!this.renderer) return;

    this.animate();
  };
}

export { BaseScene };
