import styled from "styled-components";
import { mediaMobile } from "../../utils/styles/responsive";
import { setVh } from "../../utils/styles/mixins";
import { colors, layout } from "../../utils/styles/theme";
import { EASE_FUNC } from "../../utils/styles/animations";

export const Shape = styled.svg`
  .cls-1 {
    fill: ${colors.cherryPie};
    transform: scaleX(0);
    transition: transform 0.3s ${EASE_FUNC.easeInOut};
  }
  .cls-2 {
    fill: ${colors.cherryPie};
    height: 0;
    transition: height 0.3s ${EASE_FUNC.easeInOut};
  }
  .cls-3 {
    fill: ${colors.cherryPie};
    width: 0;
    transition: width 0.3s ${EASE_FUNC.easeInOut};
  }
  .cls-4 {
    fill: none;
    stroke: ${colors.electricViolet};
    stroke-miterlimit: 10;
    stroke-width: 3px;
    stroke-dasharray: 240;
    opacity: 0;
    transition: stroke-dasharray 0.4s ${EASE_FUNC.easeInOut}, stroke 0.4s linear,
      opacity 0.4s 0.3s linear;
  }
`;

export const Projects = styled.div`
  width: 75%;
  color: ${colors.brightTurquoise};
  font-family: Archivo;
  position: absolute;
  left: 5rem;
  top: 43rem;
  opacity: 0;
  transition: opacity 0.3s ${EASE_FUNC.easeInOut};
  overflow: hidden;
  padding: 0 10rem;

  ${mediaMobile(`
    font-size: 16rem;
    top: 25rem;
  `)};
`;
export const Techs = styled.div`
  width: 87%;
  height: 22rem;
  position: absolute;
  left: 0;
  bottom: 24rem;
  opacity: 0;
  transition: opacity 0.3s ${EASE_FUNC.easeInOut};
  color: white;
  overflow: hidden;
  padding: 0 10rem;

  ${mediaMobile(`
    bottom: 6rem;
  `)};

  > svg {
    fill: #fff;
    text {
      font: 22px Archivo;
      fill: #fff;
    }
  }
`;

export const TechsItem = styled.p`
  font-size: 22rem;
  font-family: Archivo;
  opacity: 0;
  transition: opacity 0.3s ${EASE_FUNC.easeInOut};
  color: white;
  margin: 0 10rem;

  ${mediaMobile(`
    font-size: 10rem;
  `)};
`;

export const Title = styled.p`
  font-size: 26rem;
  font-family: Archivo;
  color: ${colors.brightTurquoise};
  margin: 0 20rem;

  ${mediaMobile(`
    font-size: 12rem;
  `)};
`;

export const Wrapper = styled.div`
  width: 19.79vw;
  height: auto;
  position: fixed;
  left: ${layout.appSizes.margin};
  bottom: ${layout.appSizes.margin};
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s 0.2s ${EASE_FUNC.easeInOut};
  z-index: ${layout.zIndex.ui + 1};

  /* &:hover {
    ${Shape} {
      .cls-4 {
        transition: stroke 0.4s ${EASE_FUNC.easeInOut};
        stroke: #02ffcc;
      }
    }
  } */

  &.show {
    opacity: 1;
    transition: opacity 0.3s ${EASE_FUNC.easeInOut};
    ${Projects} {
      opacity: 1;
      transition: opacity 0.5s 0.8s ${EASE_FUNC.easeInOut};
    }
    ${Techs} {
      opacity: 1;
      transition: opacity 0.5s 0.9s ${EASE_FUNC.easeInOut};
    }
    ${TechsItem} {
      opacity: 1;
      transition: opacity 0.5s 1.2s ${EASE_FUNC.easeInOut};
    }
    ${Shape} {
      .cls-1 {
        transition: transform 0.4s 0.6s ${EASE_FUNC.easeInOut};
        transform: scaleX(1);
      }
      .cls-2 {
        transition: height 0.6s 0.7s ${EASE_FUNC.easeInOut};
        height: 44px;
      }
      .cls-3 {
        transition: width 0.6s 0.6s ${EASE_FUNC.easeInOut};
        width: 44px;
      }
      .cls-4 {
        transition: stroke-dasharray 0.8s ${EASE_FUNC.easeInOut},
          stroke 0.4s 0.6s linear, opacity 0.2s linear;
        opacity: 1;
        stroke-dasharray: 1150;
        stroke: ${colors.cherryPie};
      }
    }
  }

  ${mediaMobile(`
    width: 50vw;
    bottom: ${setVh(17)};    
  `)};
`;
