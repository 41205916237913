import { debounce } from "../debounce";
import {
  isBrowser,
  isDesktop,
  isInputFocused,
  isIOS,
  isLandscape,
  isMobile,
  isRecentIPadSafari,
  isTablet,
} from "../platform";

const MOBILE_DEBOUNCE_DELAY = 250;

export interface SizeInfo {
  width: number;
  height: number;
  devicePixelRatio: number;
  isLandscape: boolean;
}

class ResizeService {
  private all: ((...args: any[]) => any)[];
  private checkInterval: number;
  private _size!: SizeInfo;
  private _initialDevicePixelRatio!: number;

  get initialDevicePixelRatio() {
    return this._initialDevicePixelRatio;
  }

  private set initialDevicePixelRatio(value: number) {
    this._initialDevicePixelRatio = value;
  }

  get size() {
    return this._size;
  }

  private set size(value: ResizeService["_size"]) {
    this._size = value;
  }

  constructor() {
    this.all = [];
    this.checkInterval = 0;
    this.size = this.getSize();

    this.addListeners();
  }

  addListeners() {
    if (!isBrowser()) return;

    // Needed for Retina screens, etc.
    this.initialDevicePixelRatio = window.devicePixelRatio;

    window.addEventListener(
      isDesktop() ? "resize" : "orientationchange",
      this.onResize,
      false
    );

    // Some devices don't trigger the resize event when the URL bar enters/exits the viewport
    if (!isDesktop() && !this.checkInterval) {
      this.checkInterval = window.setInterval(this.onResize, 1000);
    }

    this.iosFix();
    this.vhFix();
    this.setZoomLevel();
  }

  removeListeners() {
    if (!isBrowser()) return;
    window.removeEventListener(
      isDesktop() ? "resize" : "orientationchange",
      this.onResize
    );

    if (this.checkInterval) {
      window.clearInterval(this.checkInterval);
      this.checkInterval = 0;
    }
  }

  add(handler: (...args: any[]) => any, run = false) {
    if (!this.all.includes(handler)) {
      this.all.push(handler);
    }
    if (run) handler();
  }

  remove(handler: (...args: any[]) => any) {
    this.all = this.all.filter((h) => h !== handler);
  }

  private getSize(): SizeInfo {
    return {
      width: isBrowser() ? window.innerWidth : 0,
      height: isBrowser() ? window.innerHeight : 0,
      devicePixelRatio: isBrowser() ? window.devicePixelRatio : 0,
      isLandscape: isLandscape(),
    };
  }

  private iosFix() {
    if ((isIOS() || isRecentIPadSafari()) && !isInputFocused()) {
      document.body.style.minHeight = `${window.innerHeight}px`;
    }
  }

  private vhFix() {
    if (!isBrowser()) return;

    // Base it on the size of the window when there's no software keyboard on screen
    if (!isInputFocused()) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }

  private setZoomLevel() {
    // iOS doesn't scale non-px units automatically when zooming
    if (
      !process.env.ALLOW_USER_ZOOM ||
      !isBrowser() ||
      (!isDesktop() && !isIOS())
    )
      return;

    const zoomLevel = isIOS()
      ? window.outerWidth / window.innerWidth
      : this.size.devicePixelRatio;
    document.documentElement.style.setProperty(
      "--zoomLevel",
      `${zoomLevel / this.initialDevicePixelRatio}`
    );
  }

  private readonly onResize = debounce(
    () => {
      // Only update if values change
      const newSize = this.getSize();
      if (
        newSize.width === this.size.width &&
        newSize.height === this.size.height &&
        newSize.devicePixelRatio === this.size.devicePixelRatio &&
        newSize.isLandscape === this.size.isLandscape
      )
        return;

      this.iosFix();
      this.vhFix();
      this.setZoomLevel();

      // If the keyboard is open on touch devices
      if (!isDesktop() && isInputFocused()) {
        if ((isMobile() && isLandscape()) || (isTablet() && !isLandscape())) {
          // In the wrong orientation, dismiss the keyboard, so we can show the rotate screen warning
          const activeElement = document.activeElement as HTMLElement;
          activeElement.blur();
        }
      }

      this.size = newSize;
      this.all.map((handler) => handler());
    },
    // Some mobile browsers only update window.innerHeight when the rotate animation finishes
    !isDesktop() ? MOBILE_DEBOUNCE_DELAY : 0,
    true,
    true
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ResizeService();
