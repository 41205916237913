import { LoadingManager, TextureLoader } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

export class Loader {
  readonly manager = new LoadingManager();
  readonly gltf = new GLTFLoader();
  readonly draco = new DRACOLoader();
  readonly texture = new TextureLoader();
  readonly hdr = new RGBELoader();
  private isSetup = false;

  setup() {
    if (this.isSetup) return;
    this.gltf["setCrossOrigin"]("anonymous");
    this.gltf.setDRACOLoader(new DRACOLoader().setDecoderPath("/wasm/"));
    this.isSetup = true;
  }

  async loadModels(models: string[]) {
    const promises = models.map(
      async (path) => await this.gltf.loadAsync(path)
    );
    return Promise.all(promises);
  }

  async loadTextures(textures: string[]) {
    const promises = textures.map(
      async (path) => await this.texture.loadAsync(path)
    );
    return Promise.all(promises);
  }
  async loadHdr(paths: string[]) {
    const promises = paths.map(async (path) => await this.hdr.loadAsync(path));
    return Promise.all(promises);
  }
}
