import {
  AmbientLight,
  Color,
  FogExp2,
  LinearFilter,
  Object3D,
  PerspectiveCamera,
  RGBAFormat,
  SRGBColorSpace,
  Vector2,
  Vector3,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import gsap from "gsap";

// import { APP_EVENTS, EventsManager } from "../../events/EventsManager";
import { BaseScene } from "./BaseScene";
import { environment } from "./environment";
import { Head } from "../objects/Head";
import { Panel } from "../objects/Panel";
import { APP_EVENTS, EventsManager } from "../../events/EventsManager";
import { Spiral } from "../objects/Spiral";
import { Background } from "../objects/Background";
import FilmGrain from "../objects/FilmGrain";
import { delay } from "../utils/time";
import { FboHead } from "../objects/FboHead";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { isDesktop } from "utils/platform";
import { ScenesManager } from "../ScenesManager";

class StandardScene extends BaseScene {
  public renderer: WebGLRenderer;
  public environment: WebGLRenderTarget;
  private head: Head | undefined;
  private fboHead: FboHead | undefined;
  private spiral: Spiral | undefined;
  private background: Background | undefined;
  private filmGrain: FilmGrain | undefined;
  private panel: Panel | undefined;
  bloomTween!: gsap.core.Tween;
  controls: OrbitControls;

  hasHead = false;
  postProcess:
    | {
        composer: EffectComposer;
        renderPass: RenderPass;
        unrealBloomPass: UnrealBloomPass;
      }
    | undefined;
  sizes:
    | {
        width: number;
        height: number;
        pixelRatio: number;
      }
    | undefined;
  renderTarget: any;
  instance: any;

  constructor(
    readonly canvas: HTMLCanvasElement,
    readonly onStarted: () => void,
    readonly onUpdate: () => void
  ) {
    super(canvas);
    this.canvas = canvas;
    this.renderer = new WebGLRenderer({
      antialias: true,
      canvas: canvas,
      alpha: true,
      powerPreference: "high-performance",
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.setStandardSceneDPR();

    this.environment = environment(this.renderer);
    this.scene.environment = this.environment.texture;
    this.camera = new PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      100
    );
    this.camera.position.set(0, 0, 6);
    this.camera.updateMatrix();
    this.scene.add(this.camera);

    this.camera.far = 10000;
    this.camera.updateProjectionMatrix();
    const env = environment(this.renderer);
    this.scene.environment = env.texture;

    this.controls = new OrbitControls(this.camera, this.canvas);
    this.controls.enableDamping = true;
    this.controls.minDistance = 0;
    this.controls.maxDistance = 2000;
    this.controls.enabled = false;
    this.controls.target = new Vector3(0, 0, 0);

    this.scene.background = new Color(0x000000);
    this.scene.fog = new FogExp2(0x000000, 0.0025);
    this.scene.add(new AmbientLight());

    this.setPostProcess();

    this.container = new Object3D();
    this.scene.add(this.container);

    this.build();

    this.renderer.setAnimationLoop(() => {
      onUpdate();
      // this.controls.update();
    });

    onStarted && onStarted();
  }

  public setStandardSceneDPR() {
    const DPR = Math.min(window.devicePixelRatio, 2);
    this.renderer.setPixelRatio(DPR);
    this.resize();
  }

  createPanel() {
    if (this.panel) return;
    this.panel = new Panel(
      window.location.pathname.includes("about")
        ? "exploded"
        : window.location.pathname.includes("project")
        ? "expanded"
        : "retracted"
    );
    this.panel.position.set(isDesktop() ? 1.2 : 1.4, 1.7, -0.75);
    this.scene.add(this.panel);
  }

  createHead() {
    if (this.head) return;
    this.head = new Head();
    this.container?.add(this.head);
  }
  createFboHead() {
    if (this.fboHead) return;
    this.fboHead = new FboHead();
    this.fboHead.position.set(isDesktop() ? -0.1 : 0.1, -0.15, 0);
    this.container?.add(this.fboHead);

    if (this.spiral) return;
    this.spiral = new Spiral();
    this.fboHead.add(this.spiral);
  }

  createBackground() {
    if (this.background) return;
    this.background = new Background();
    this.background.position.z = -3;
    this.scene.add(this.background);
  }

  async build() {
    this.createPanel();
    await delay(1);
    this.createFboHead();
    await delay(1);

    this.filmGrain = new FilmGrain();
    this.filmGrain.position.set(0, 0, 2);
    this.scene.add(this.filmGrain);

    EventsManager.instance.on(APP_EVENTS.PANEL_UPDATE, async (event) => {
      if (event.data.video) {
        ScenesManager.inTransition = true;
        if (!this.panel) {
          this.createPanel();
        }
        this.panel?.updateVideo(event.data.video);
        await delay(2);
        ScenesManager.inTransition = false;
      }
    });
    EventsManager.instance.on(APP_EVENTS.PANEL_EXPAND, async (event) => {
      if (event.data.fromHome) {
        ScenesManager.inTransition = true;
        if (!this.panel) {
          this.createPanel();
        }
        this.panel?.expand(true);
        this.filmGrain?.hide(false);
        await delay(2);
        ScenesManager.inTransition = false;
      }
    });
    EventsManager.instance.on(APP_EVENTS.PANEL_RETRACT, async () => {
      if (!this.panel) {
        ScenesManager.inTransition = true;
        this.createPanel();
      }
      this.panel?.retract();
      this.filmGrain?.show();
      await delay(2);
      this.toggleBloom(false);

      ScenesManager.inTransition = false;
    });
    EventsManager.instance.on(APP_EVENTS.PANEL_EXPLODE, async () => {
      ScenesManager.inTransition = true;
      if (!this.panel) {
        this.createPanel();
      }
      // this.filmGrain?.hide(true);
      this.panel?.explode(false);
      await delay(3);
      ScenesManager.inTransition = false;
    });

    EventsManager.instance.on(APP_EVENTS.HEAD_SHOW, async () => {
      ScenesManager.inTransition = true;
      if (!this.fboHead) {
        this.createFboHead();
      }
      this.fboHead?.show();
      this.spiral?.show();
      this.filmGrain?.hide(true);
      this.toggleBloom(true);
      this.hasHead = true;
      await delay(4);
      ScenesManager.inTransition = false;
    });
    EventsManager.instance.on(APP_EVENTS.HEAD_HIDE, async () => {
      ScenesManager.inTransition = true;
      this.spiral?.hide();
      this.filmGrain?.show();
      this.toggleBloom(false);
      await this.fboHead?.hide();
      this.hasHead = false;
      await delay(3);
      ScenesManager.inTransition = false;
    });
  }

  setPostProcess() {
    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: Math.min(window.devicePixelRatio, 2),
    };
    /**
     * Effect composer
     */

    this.renderTarget = new WebGLRenderTarget(
      this.sizes.width,
      this.sizes.height,
      {
        generateMipmaps: false,
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBAFormat,
        colorSpace: SRGBColorSpace,
        samples: this.renderer.getPixelRatio() === 1 ? 2 : 0,
      }
    );
    this.postProcess = {
      composer: new EffectComposer(this.renderer, this.renderTarget),
      renderPass: new RenderPass(this.scene, this.camera),
      unrealBloomPass: new UnrealBloomPass(
        new Vector2(this.sizes.width, this.sizes.height),
        0,
        0,
        0
      ),
    };
    this.postProcess.composer.setSize(this.sizes.width, this.sizes.height);
    this.postProcess.composer.setPixelRatio(this.sizes.pixelRatio);

    /**
     * Passes
     */
    // Render pass

    // Bloom pass
    this.postProcess.unrealBloomPass.enabled = true;

    if (ScenesManager.tweakPane) {
      const f1 = ScenesManager.tweakPane.addFolder({
        title: "Bloom",
      });

      // f1.addBinding(this.postProcess.unrealBloomPass, "value", {
      //   picker: "inline",
      //   expanded: true,
      // });
      f1.addBinding(this.postProcess.unrealBloomPass, "strength", {
        min: 0.0,
        max: 3.0,
      });
      f1.addBinding(this.postProcess.unrealBloomPass, "radius", {
        min: 0.0,
        max: 4.0,
      });
      f1.addBinding(this.postProcess.unrealBloomPass, "threshold", {
        min: 0.0,
        max: 10.0,
      });
    }

    this.postProcess.unrealBloomPass.compositeMaterial.uniforms.uTintColor = {
      value: new Vector3(0.0, 0.0, 0.0),
    };
    this.postProcess.unrealBloomPass.compositeMaterial.uniforms.uTintStrength =
      { value: 0.0 };
    this.postProcess.unrealBloomPass.compositeMaterial.fragmentShader = /* glsl */ `
      varying vec2 vUv;
      uniform sampler2D blurTexture1;
      uniform sampler2D blurTexture2;
      uniform sampler2D blurTexture3;
      uniform sampler2D blurTexture4;
      uniform sampler2D blurTexture5;
      uniform sampler2D dirtTexture;
      uniform float bloomStrength;
      uniform float bloomRadius;
      uniform float bloomFactors[NUM_MIPS];
      uniform vec3 bloomTintColors[NUM_MIPS];
      uniform vec3 uTintColor;
      uniform float uTintStrength;

      float lerpBloomFactor(const in float factor) {
        float mirrorFactor = 1.2 - factor;
        return mix(factor, mirrorFactor, bloomRadius);
      }

      void main() {
        vec4 color = bloomStrength * ( lerpBloomFactor(bloomFactors[0]) * vec4(bloomTintColors[0], 1.0) * texture2D(blurTexture1, vUv) +
        lerpBloomFactor(bloomFactors[1]) * vec4(bloomTintColors[1], 1.0) * texture2D(blurTexture2, vUv) +
        lerpBloomFactor(bloomFactors[2]) * vec4(bloomTintColors[2], 1.0) * texture2D(blurTexture3, vUv) +
        lerpBloomFactor(bloomFactors[3]) * vec4(bloomTintColors[3], 1.0) * texture2D(blurTexture4, vUv) +
        lerpBloomFactor(bloomFactors[4]) * vec4(bloomTintColors[4], 1.0) * texture2D(blurTexture5, vUv) );

        color.rgb = mix(color.rgb, uTintColor, uTintStrength);
        gl_FragColor = color;
      }
    `;

    this.postProcess.composer.addPass(this.postProcess.renderPass);
    this.postProcess.composer.addPass(this.postProcess.unrealBloomPass);
    this.toggleBloom(false);
  }

  toggleBloom(active: boolean) {
    if (this.postProcess) {
      this.bloomTween?.kill();
      this.bloomTween = gsap.to(this.postProcess.unrealBloomPass, {
        strength: active ? 1.63 : 0,
        radius: active ? 1 : 0,
        duration: active ? 2 : 1,
        delay: active ? 3 : 0,
      });
    }
  }

  readonly pause = () => {
    console.log("StandardScene pause");
  };

  readonly resume = () => {
    console.log("StandardScene resume");
  };

  readonly stop = () => {
    console.log("StandardScene stop");
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  readonly load = () => {};
  readonly start = () => {};

  readonly animate = () => {
    if (this.hasHead) {
      this.head?.animate();
      this.fboHead?.animate();
      this.spiral?.animate();
      this.background?.animate();
    }
    this.filmGrain?.animate();

    if (this.controls && this.controls.enabled) this.controls?.update();

    if (this.postProcess?.composer) {
      this.postProcess.composer.render();
    } else {
      this.renderer.render(this.scene, this.camera);
    }
  };

  dispose() {
    if (this.environment) {
      this.environment.dispose();
    }
  }
}

export { StandardScene };
