export enum ColorNames {
  white = "white",
  black = "black",
  cherryPie = "cherryPie",
  electricViolet = "electricViolet",
  brightTurquoise = "brightTurquoise",
  grad0 = "grad0",
  grad1 = "grad1",
  grad2 = "grad2",
  grad3 = "grad3",
}
export enum FontNames {
  unityHead = "TCCC Unity Head",
  unity = "TCCC Unity",
  unityCondensed = "TCCCondensed",
}

const validFontCategories = [
  "heading1",
  "heading2",
  "heading3",
  "heading4",
  "body1",
  "body2",
  "button",
  "input",
  "small",
  "caption",
  "label",
  "loading",
] as const;

export interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

export interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues;
  lineHeight: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: "expanded" | "normal";
  textDecoration?: "underline" | "none";
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "full-size-kana"
    | "full-width"
    | "lowercase";
  fontStyle?: "normal" | "italic" | "oblique" | "inherit" | "initial" | "unset";
}

export interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<(typeof validFontCategories)[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme["colors"] = {
  [ColorNames.white]: "#ccc",
  [ColorNames.black]: "#000",
  [ColorNames.cherryPie]: "#2B0053",
  [ColorNames.electricViolet]: "#8200ff",
  [ColorNames.brightTurquoise]: "#00ffcc",

  [ColorNames.grad0]: "#ff0084",
  [ColorNames.grad1]: "#ff6600",
  [ColorNames.grad2]: "#ccff00",
  [ColorNames.grad3]: "#00ffd2",
} as const;

export const fonts: Theme["fonts"] = {
  face: {
    [FontNames.unityHead]:
      "'TCCC Unity Head', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.unity]:
      "'TCCC Unity', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.unityCondensed]:
      "'TCCC', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  scale: {
    // fontSize: rem, letterSpacing: em
    // fontFamily: { [localeCode]: localeSpecificFont, default: defaultFont }
    heading1: {
      fontFamily: { default: FontNames.unityHead },
      fontSize: { tablet: 26, mobile: 22 },
      lineHeight: { tablet: 1.35, mobile: 1.35 },
      fontWeight: 400,
    },
    heading2: {
      fontFamily: { default: FontNames.unityHead },
      fontSize: { tablet: 18, mobile: 18 },
      lineHeight: { tablet: 1.2, mobile: 1.2 },
      fontWeight: 400,
    },
    heading3: {
      fontFamily: { default: FontNames.unityHead },
      fontSize: { tablet: 14, mobile: 14 },
      lineHeight: { tablet: 1.35, mobile: 1.35 },
      fontWeight: 700,
    },
    heading4: {
      fontFamily: { default: FontNames.unityHead },
      fontSize: { tablet: 14, mobile: 14 },
      lineHeight: { tablet: 1.35, mobile: 1.35 },
      fontWeight: 40,
    },
    body1: {
      fontFamily: { default: FontNames.unity },
      fontSize: { tablet: 12, mobile: 12 },
      lineHeight: { tablet: 1.5, mobile: 1.5 },
      fontWeight: 400,
      letterSpacing: 0.03,
    },
    body2: {
      fontFamily: { default: FontNames.unity },
      fontSize: { tablet: 14, mobile: 14 },
      lineHeight: { tablet: 1.15, mobile: 1.15 },
      fontWeight: 400,
    },
    loading: {
      fontFamily: { default: FontNames.unityHead },
      fontSize: { tablet: 18, mobile: 18 },
      lineHeight: { tablet: 1.25, mobile: 1.25 },
      fontWeight: 400,
      letterSpacing: 0.05,
    },
    button: {
      fontFamily: { default: FontNames.unity },
      fontSize: { tablet: 14, mobile: 10 },
      lineHeight: { tablet: 1.5, mobile: 1.5 },
      fontWeight: 400,
      letterSpacing: 0.04,
    },
    input: {
      fontFamily: { default: FontNames.unity },
      fontSize: { tablet: 16, mobile: 10 },
      lineHeight: { tablet: 1.5, mobile: 1.5 },
      fontWeight: 400,
    },
    small: {
      fontFamily: { default: FontNames.unity },
      fontSize: { tablet: 6, mobile: 6 },
      lineHeight: { tablet: 1.5, mobile: 1.5 },
      fontWeight: 400,
      letterSpacing: 0,
    },
    caption: {
      fontFamily: { default: FontNames.unity },
      fontSize: { tablet: 14, mobile: 10 },
      lineHeight: { tablet: 1.5, mobile: 1.5 },
      fontWeight: 400,
      letterSpacing: 0.05,
      textTransform: "uppercase",
    },
    label: {
      fontFamily: { default: FontNames.unity },
      fontSize: { tablet: 12, mobile: 8 },
      lineHeight: { tablet: 1.5, mobile: 1.5 },
      fontWeight: 400,
      letterSpacing: 0.05,
      textTransform: "uppercase",
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  borderRadius: 14, // rem
  lineOpacity: 0.1,
  button: {
    default: {
      w: 100,
      h: 40,
    },
  },
  zIndex: {
    bottom: 1,
    nonFunctionals: 2,
    ui: 3,
    modals: 10,
  },
  appSizes: {
    deskMin: 1024,
    deskMax: 1920,
    maxCtrWidth: 1590,
    margin: "4.6875vw",
    margin2: "15.375vw",
    menuDeskptopHeight: "70rem",
    menuMobileHeight: "50rem",
  },
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;
