import gsap from "gsap";
import {
  AdditiveBlending,
  DataTexture,
  FloatType,
  Mesh,
  Object3D,
  RGBAFormat,
  ShaderMaterial,
  Uniform,
  Vector2,
  Vector3,
} from "three";
import { ScenesManager } from "../ScenesManager";
import FBO from "./FBO";
import { simFragment, simVertex } from "../shaders/particles/simulation";
import {
  particlesVertex,
  particlesFragment,
} from "../shaders/particles/particles";
import { isDesktop } from "utils/platform";

// https://github.com/MisterPrada/morph-particles/tree/master
// https://codepen.io/prisoner849/pen/yLGGKqo?editors=0010
// https://codepen.io/prisoner849/pen/zYRjBWN?editors=0010

export class FboHead extends Object3D {
  transitioning = false;
  introDone = false;
  headGeometry: any;
  e2Geometry: any;
  simMaterial!: ShaderMaterial;
  renderMaterial!: ShaderMaterial;
  sizes: any;
  range: any;
  fbo: any;
  simTween!: gsap.core.Tween;
  renderTween!: gsap.core.Tween;
  constructor() {
    super();

    this.build();
  }

  build() {
    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: Math.min(window.devicePixelRatio, 2),
    };

    this.setFBOParticles();
  }

  makeTexture(g: { attributes: { position: { count: any; array: any[] } } }) {
    let vertAmount = g.attributes.position.count;
    let texWidth = Math.ceil(Math.sqrt(vertAmount));
    let texHeight = Math.ceil(vertAmount / texWidth);

    let data = new Float32Array(texWidth * texHeight * 4);

    function shuffleArrayByThree(array: number[]) {
      const groupLength = 3;

      let numGroups = Math.floor(array.length / groupLength);

      for (let i = numGroups - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));

        for (let k = 0; k < groupLength; k++) {
          let temp = array[i * groupLength + k];
          array[i * groupLength + k] = array[j * groupLength + k];
          array[j * groupLength + k] = temp;
        }
      }

      return array;
    }

    shuffleArrayByThree(g.attributes.position.array);

    for (let i = 0; i < vertAmount; i++) {
      //let f = Math.floor(Math.random() * (randomTemp.length / 3) );

      const x = g.attributes.position.array[i * 3 + 0];
      const y = g.attributes.position.array[i * 3 + 1];
      const z = g.attributes.position.array[i * 3 + 2];
      const w = 0;

      //randomTemp.splice(f * 3, 3);

      data[i * 4 + 0] = x;
      data[i * 4 + 1] = y;
      data[i * 4 + 2] = z;
      data[i * 4 + 3] = w;
    }

    let dataTexture = new DataTexture(
      data,
      texWidth,
      texHeight,
      RGBAFormat,
      FloatType
    );
    dataTexture.needsUpdate = true;

    return dataTexture;
  }

  setFBOParticles() {
    // width and height of FBO
    const width = 256;
    const height = 256;

    //returns an array of random 3D coordinates
    function getRandomData(width: number, height: number, size: number) {
      const len = width * height * 4;
      const data = new Float32Array(len);
      //while( len-- )data[len] = ( Math.random() -.5 ) * size ;
      for (let i = 0; i < len; i++) {
        data[i * 3 + 0] = (Math.random() - 0.5) * size;
        data[i * 3 + 1] = (Math.random() - 0.5) * size;
        data[i * 3 + 2] = (Math.random() - 0.5) * size;
      }

      return data;
    }

    this.headGeometry = (
      ScenesManager.assets.head.scene.children[0] as Mesh
    ).geometry;
    this.headGeometry.scale(5.5, 5.5, 5.5);

    this.e2Geometry = (
      ScenesManager.assets.e2.scene.children[0] as Mesh
    ).geometry;
    this.e2Geometry.scale(5.5, 5.5, 5.5);

    const uTextureA = this.makeTexture(this.headGeometry);

    const data = getRandomData(width, height, 15);
    const positions = new DataTexture(
      data,
      width,
      height,
      RGBAFormat,
      FloatType
    );
    positions.needsUpdate = true;
    const uTextureB = this.makeTexture(this.e2Geometry);
    const uTextureC = positions;

    //simulation shader used to update the particles' positions
    const uTotalModels = 2.0;
    this.simMaterial = new ShaderMaterial({
      uniforms: {
        uTextureA: { value: uTextureA },
        uTextureB: { value: uTextureB },
        uTextureC: { value: uTextureC },
        uTime: { value: 0 },
        uScroll: { value: 0.49 },
        uTotalModels: { value: uTotalModels },
      },
      vertexShader: simVertex,
      fragmentShader: simFragment,
    });

    //render shader to display the particles on screen
    //the 'positions' uniform will be set after the FBO.update() call
    this.renderMaterial = new ShaderMaterial({
      uniforms: {
        uPositions: { value: null },
        uSize: { value: 0.5 },
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uScroll: { value: 0.2 },
        uOpacity: { value: 0.0 },
        uColor: { value: new Vector3(0.46, 0.0, 1.0) },
        uResolution: new Uniform(
          new Vector2(
            this.sizes.width * this.sizes.pixelRatio,
            this.sizes.height * this.sizes.pixelRatio
          )
        ),
      },
      vertexShader: particlesVertex,
      fragmentShader: particlesFragment,
      transparent: true,
      depthWrite: false,
      blending: AdditiveBlending,
    });

    this.renderMaterial.defines = {
      defines: {
        uTotalModels: uTotalModels,
        uRange: 1.0 / uTotalModels,
      },
    };

    // Initialize the FBO
    this.fbo = new FBO(width, height, this.simMaterial, this.renderMaterial);
    this.fbo.particles.position.set(-0.025, 0, isDesktop() ? 0.7 : -0.2);
    this.fbo.particles.rotation.z = Math.PI * 2;
    if (ScenesManager.tweakPane) {
      const f1 = ScenesManager.tweakPane.addFolder({
        title: "Particles",
      });

      f1.addBinding(this.renderMaterial.uniforms.uColor, "value", {
        picker: "inline",
        expanded: true,
      });
      f1.addBinding(this.simMaterial.uniforms.uScroll, "value", {
        min: 0.0,
        max: 1.0,
      });
      f1.addBinding(this.fbo.particles.position, "z", {
        min: -10.0,
        max: 10.0,
      });
      f1.addBinding(this.fbo.particles.position, "y", {
        min: -10.0,
        max: 10.0,
      });
    }
    // Add the particles to the scene
    this.add(this.fbo.particles);

    this.resize();
  }

  show() {
    return new Promise((resolve) => {
      const totalDuration = isDesktop() ? 2 : 1.3;
      this.transitioning = true;
      this.simTween?.kill();
      this.simTween = gsap.to(this.simMaterial.uniforms.uScroll, {
        value: 0.0,
        duration: totalDuration,
        ease: "power0.inOut",
        delay: 0.8,
        onComplete: () => {
          this.introDone = true;
          ScenesManager.followMouseEnabled = true;
          resolve(true);
        },
      });
      this.renderTween?.kill();
      this.renderTween = gsap.to(this.renderMaterial.uniforms.uOpacity, {
        value: 1.0,
        duration: totalDuration * 0.8,
        delay: totalDuration * 0.4,
      });
    });
  }

  hide() {
    return new Promise(async (resolve) => {
      this.renderTween?.kill();
      this.renderTween = gsap.to(this.renderMaterial.uniforms.uOpacity, {
        value: 0.0,
        duration: 0.4,
        ease: "sine.inOut",
      });
      this.simTween?.kill();
      this.simTween = gsap.to(this.simMaterial.uniforms.uScroll, {
        value: 0.49,
        duration: 1,
        onComplete: () => {
          this.introDone = false;
          this.transitioning = false;
          ScenesManager.followMouseEnabled = false;
          resolve(true);
        },
      });
    });
  }

  resize() {
    this.fbo?.resize(this.sizes.width, this.sizes.height);
    if (this.renderMaterial) {
      this.renderMaterial.uniforms.uPixelRatio.value = Math.min(
        window.devicePixelRatio,
        2
      );
      this.renderMaterial.uniforms.uResolution.value.set(
        this.sizes.width * this.sizes.pixelRatio,
        this.sizes.height * this.sizes.pixelRatio
      );
    }
  }

  animate() {
    const time = performance.now() * 0.005; //ScenesManager.clock.getElapsedTime();
    if (this.simMaterial) this.simMaterial.uniforms.uTime.value = time;
    if (this.renderMaterial) this.renderMaterial.uniforms.uTime.value = time;
    this.fbo?.update();

    // this.fbo.particles.rotation.z += 0.05;
  }
}
