import { create } from "zustand";

export interface GlobalStoreType {
  projectID: number;
  setProjectID: (state: number) => void;

  isLoading: boolean;
  setIsLoading: (state: boolean) => void;

  showUI: boolean;
  setShowUI: (state: boolean) => void;

  showNavBar: { visible: boolean; delay: number };
  setShowNavBar: (visible: boolean, delay: number) => void;
}

const useGlobalStore = create<GlobalStoreType>(
  (
    set: (partial: (store: GlobalStoreType) => Partial<GlobalStoreType>) => void
  ) => {
    return {
      projectID: 0,
      setProjectID: (state) => {
        set(() => ({
          projectID: state,
        }));
      },
      isLoading: true,
      setIsLoading: (state) => {
        set(() => ({
          isLoading: state,
        }));
      },
      showUI: false,
      setShowUI: (state) => {
        set(() => ({
          showUI: state,
        }));
      },
      showNavBar: {
        visible: false,
        delay: 1,
      },
      setShowNavBar: (visible: boolean, delay: number) => {
        set(() => ({
          showNavBar: {
            visible: visible,
            delay: delay,
          },
        }));
      },
    };
  }
);

export default useGlobalStore;
