import { PMREMGenerator, WebGLRenderer } from "three";

import { RoomEnvironment } from "../utils/CustomRoomEnvironment";

export function environment(renderer: WebGLRenderer) {
  let envScene = new RoomEnvironment();
  const pmremGenerator = new PMREMGenerator(renderer);
  pmremGenerator.compileCubemapShader();

  const generator = pmremGenerator.fromScene(envScene);
  envScene.children = [];

  return generator;
}
