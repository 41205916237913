import React, { useCallback, useEffect, useState } from "react";
import { findIndex } from "lodash";

import {
  pageMotionProps,
  fadeMotionProps,
} from "../../utils/styles/animations";

import * as Styled from "./ProjectPage.styles";
import { useParams } from "react-router-dom";
import useGlobalStore from "../../utils/store";
import { IProject } from "../../utils/types";
import { PROJECT_IDS } from "../../data/content";
import Marquee from "react-fast-marquee";
import { AnimatePresence } from "framer-motion";

const ProjectPage: React.FunctionComponent = () => {
  const { pathName } = useParams();
  const { projectID, setProjectID, setShowNavBar, isLoading, showUI } =
    useGlobalStore();
  const [show, setShow] = useState<boolean>(false);
  
  const [items, setItems] = useState<React.JSX.Element[]>([]);
  const [currentProject, setCurrentProject] = useState<IProject>(
    PROJECT_IDS.work[projectID]
  );

  const getContent = useCallback(() => {
    const _items: React.JSX.Element[] = [];
    currentProject.content.forEach((content, i) => {
      _items.push(
        <div key={content.key}>
          {content.title && (
            <Styled.ContentTitle
              key={`${content.key}-title`}
              {...fadeMotionProps}
              transition={{
                duration: 0.6,
                delay: 0.3 + i / 10,
              }}
              dangerouslySetInnerHTML={{ __html: content.title }}
            />
          )}
          {content.description && (
            <Styled.ContentText
              key={`${content.key}-text`}
              {...fadeMotionProps}
              transition={{
                duration: 0.6,
                delay: 0.4 + i / 10,
              }}
              dangerouslySetInnerHTML={{ __html: content.description }}
            />
          )}
          {content.image && (
            <Styled.ContentImage
              key={`${content.key}-image`}
              {...fadeMotionProps}
              transition={{
                duration: 0.6,
                delay: 0.5 + i / 10,
              }}
              src={content.image}
            />
          )}
          {content.video && (
            <Styled.ContentVideo
              key={`${content.key}-video`}
              {...fadeMotionProps}
              transition={{
                duration: 0.6,
                delay: 0.5 + i / 10,
              }}
              loop
              crossOrigin="anonymous"
              autoPlay={true}
              playsInline
              muted
              preload="auto"
            >
              <source src={content.video} type="video/mp4" />
            </Styled.ContentVideo>
          )}
        </div>
      );
    });
    setItems(_items);
  }, [currentProject.content, setItems]);
  
  useEffect(() => {
    setShowNavBar(false, 0);
    const currentProjectIndex = findIndex(
      PROJECT_IDS.work,
      (p) => p.path === pathName
    );
    setProjectID(currentProjectIndex);
    setCurrentProject(PROJECT_IDS.work[currentProjectIndex]);  
  }, [pathName, setProjectID, setShowNavBar])

  useEffect(() => {
    if (showUI && !isLoading) {
      setTimeout(() => {
        setShow(true);
        getContent();
      }, 500);
    }

    return () => {
      setShow(false);
    };
  }, [getContent, isLoading, showUI]);
  
  return (
    <AnimatePresence>
      {show && (
        <Styled.Wrapper {...pageMotionProps} key={currentProject.id}>
          <Styled.MarqueeWrapper
            {...fadeMotionProps}
            transition={{
              duration: 0.6,
              delay: 0,
            }}
          >
            <Marquee speed={80} direction="right">
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
            </Marquee>
          </Styled.MarqueeWrapper>
          <Styled.MarqueeWrapperReverse
            {...fadeMotionProps}
            transition={{
              duration: 0.6,
              delay: 0.1,
            }}
          >
            <Marquee speed={100}>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
              <Styled.MarqueeText>{currentProject.title}</Styled.MarqueeText>
            </Marquee>
          </Styled.MarqueeWrapperReverse>
          {items && <Styled.ContentWrapper key={`${currentProject.id}-content`}>
            {items}
          </Styled.ContentWrapper>
          }
        </Styled.Wrapper>
      )}
    </AnimatePresence>
  );
};

export default ProjectPage;
