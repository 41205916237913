import React from "react";

import { pageMotionProps } from "../../utils/styles/animations";

import * as Styled from "./NotFoundPage.styles";

const NotFoundPage: React.FunctionComponent = () => {

  return (
    <Styled.Wrapper {...pageMotionProps}>
        <h1>NOT FOUND PAGE</h1>
    </Styled.Wrapper>
  );
};

export default NotFoundPage;
