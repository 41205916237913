import gsap from "gsap";
import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Object3D,
  Points,
  ShaderMaterial,
  Vector3,
} from "three";
import { ScenesManager } from "../ScenesManager";
import { headFragment, headVertex } from "../shaders/head";
import { isDesktop } from "utils/platform";

export class Background extends Object3D {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  points:
    | {
        object: Points | undefined;
        surrounding: number[];
        shape: number[];
        around: number[];
        sizes: number[];
        colors: number[];
        pointSize: number;
        animation: {
          in: {
            lerpFactor: number;
          };
          out: {
            lerpFactor: number;
          };
        };
      }
    | undefined;
  transitioning = false;
  introDone = false;
  constructor() {
    super();

    this.build();
  }

  build() {
    this.points = {
      object: undefined,
      surrounding: [],
      shape: [],
      around: [],
      sizes: [],
      colors: [],
      pointSize: isDesktop() ? 0.15 : 0.4,
      animation: {
        in: {
          lerpFactor: 0.03,
        },
        out: {
          lerpFactor: 0.01,
        },
      },
    };
    const color = new Color(0xffffff);
    const amount = isDesktop() ? 25000 : 15000;
    const vertex = new Vector3();
    for (let i = 0; i < amount; i += 3) {
      if (isDesktop()) {
        vertex.x = Math.random() * 140 - 70;
        vertex.y = Math.random() * 30 - 15;
        vertex.z = Math.random() * 80 - 40;
      } else {
        vertex.x = Math.random() * 50 - 25;
        vertex.y = Math.random() * 30 - 15;
        vertex.z = Math.random() * 90 - 45;
      }
      this.points.surrounding.push(vertex.x, vertex.y, vertex.z);

      color.toArray(this.points.colors, i * 3);

      this.points.sizes[i] =
        i % 10 === 0
          ? Math.random() * this.points.pointSize * 0.5
          : this.points.pointSize;
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute(
      "position",
      new Float32BufferAttribute(this.points.surrounding, 3)
    );
    geometry.setAttribute(
      "size",
      new Float32BufferAttribute(this.points.sizes, 1)
    );
    geometry.setAttribute(
      "customColor",
      new Float32BufferAttribute(this.points.colors, 3)
    );

    this.points.object = new Points(geometry, this.getMaterial());
    this.points.object.rotation.set(Math.PI / 2, 0, 0);
    this.points.object.scale.set(0.15, 0.15, 0.15);
    this.points.object.position.set(
      isDesktop() ? -0.15 : 0,
      isDesktop() ? -0.4 : -0.25,
      isDesktop() ? 0.5 : -0.6
    );

    this.add(this.points.object);
  }

  getMaterial() {
    const color = new Color(0xffffff);
    const material = new ShaderMaterial({
      transparent: true,
      blending: AdditiveBlending,
      depthTest: false,
      uniforms: {
        uOpacity: { value: 0.0 },
        uTime: { value: 0.0 },
        color: { value: color },
        pointTexture: { value: ScenesManager.assets.star },
      },
      vertexShader: headVertex,
      fragmentShader: headFragment,
    });

    return material;
  }

  show() {
    return new Promise((resolve) => {
      if (this.points && this.points.object) {
        gsap.to(
          (this.points.object.material as ShaderMaterial).uniforms.uOpacity,
          {
            value: 1.0,
            duration: 2,
            delay: 2,
            onStart: () => {
              resolve(true);
            },
          }
        );
      }
    });
  }

  hide() {
    return new Promise((resolve) => {
      if (this.points && this.points.object) {
        const material = this.points.object.material as ShaderMaterial;
        gsap.to(material.uniforms.uOpacity, {
          value: 0.0,
          duration: 0.6,
        });
      }
      resolve(true);
    });
  }

  animate() {
    const timeShader = performance.now() * 0.005;
    if (this.points && this.points.object) {
      const attrs = this.points.object.geometry.attributes;
      const size = attrs.size;
      (this.points.object.material as ShaderMaterial).uniforms.uTime.value =
        timeShader;
      for (let i = size.array.length * 0.3; i < size.array.length; i++) {
        size.array[i] =
          this.points.pointSize + 0.15 * Math.sin(i + 0.6 * timeShader);
      }
      size.needsUpdate = true;
    }
  }
}
