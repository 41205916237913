import React, { useCallback, useEffect, useRef, useState } from "react";

import { pageMotionProps } from "../../utils/styles/animations";

import * as Styled from "./HomePage.styles";
import { useSwipeable } from "react-swipeable";
import useGlobalStore from "utils/store";
import { PROJECT_IDS } from "data/content";
import { isDesktop } from "utils/platform";

const HomePage: React.FunctionComponent = () => {
  const { projectID, setProjectID, isLoading, showUI } =
    useGlobalStore();
  const swipeEnabled = useRef<boolean>(false);
  const scrollTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const currentProjectID = useRef<number>(projectID);
  const [show, setShow] = useState<boolean>(false);

  const goToIndex = useCallback((dir: "Left" | "Right" | "Up" | "Down") => {
    let nextIndex = dir === "Left" ? currentProjectID.current + 1 : currentProjectID.current - 1;
    if (nextIndex > PROJECT_IDS.work.length - 1) nextIndex = 0;
    if (nextIndex < 0) nextIndex = PROJECT_IDS.work.length - 1;

    setProjectID(nextIndex);
  },[setProjectID]);

  const handlers = useSwipeable(
    {
      onSwiped: (eventData) => {
        if (!swipeEnabled.current) return;
        goToIndex(eventData.dir);
      },
      ...{
        trackMouse: true,
      }
    },
    
  );

  const onKeyDown = useCallback((e: { key: any; }) => {
    switch (e.key) {
      case "ArrowLeft":
        goToIndex("Right");
        break;
        case "ArrowRight":
        goToIndex("Left");
        break;
    }
  }, [goToIndex]);
  
  const onWheel = useCallback((event: { preventDefault: () => void; deltaY: number; }) => {
    event.preventDefault();
    clearTimeout(scrollTimerRef.current);
    scrollTimerRef.current = setTimeout(() => {
      clearTimeout(scrollTimerRef.current);
      if (event.deltaY > 0) {
        goToIndex("Left");
      } else {
        goToIndex("Right");
      }
    }, 200);
  }, [goToIndex]);

   useEffect(() => {
     if (showUI && !isLoading) {
       setTimeout(() => {
         swipeEnabled.current = true;

         if (isDesktop()) {
           document.addEventListener("keydown", onKeyDown);
           window.addEventListener("wheel", onWheel);
         } else {

           setShow(true);
         }
        }, 1500);
     }

     return () => {
       swipeEnabled.current = false;
       setShow(false);
       document.removeEventListener("keydown", onKeyDown);
       window.removeEventListener("wheel", onWheel);
     };
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showUI, isLoading]);

   useEffect(() => {
     currentProjectID.current = projectID;
   }, [projectID]);

  return (
    <Styled.Wrapper {...handlers} {...pageMotionProps}>
      {show && (
        <>
          <Styled.ArrowLeft
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1, duration: 0.6 }}
            onClick={() => goToIndex("Right")} />
          <Styled.ArrowRight
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1, duration: 0.6 }}
            onClick={() => goToIndex("Left")} />
        </>
      )}
    </Styled.Wrapper>
  );
};

export default HomePage;
