import React, { useMemo } from 'react';
import gsap from "gsap";
import * as Styled from './Menu.styles';
import { ROUTES } from '../../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import { loadingMotionProps } from "../../utils/styles/animations";
import { colors } from '../../utils/styles/theme';
import { isDesktop } from '../../utils/platform';
import useGlobalStore from '../../utils/store';
import { APP_EVENTS, EventsManager } from '../../webgl/events/EventsManager';
import { SoundController } from 'webgl/controllers/SoundController';
import { SOUND_LABELS } from '../../data/sound';
import { ScenesManager } from 'webgl/scenes/ScenesManager';

export interface MenuProps {}

const Menu: React.FC<MenuProps> = () => {  
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktopView = useMemo(() => isDesktop(), []);
  const { setShowNavBar } = useGlobalStore();

  const updateUI = (path: string, options: any, target: 'about' | 'projects') => {
    if (
      (location.pathname === ROUTES.ABOUT && target === "about") ||
      (location.pathname === ROUTES.HOME && target === "projects") ||
      ScenesManager.inTransition
    ) {
      return;
    }
    ScenesManager.inTransition = true;
    navigate(path, options);
    setShowNavBar(false, 0);
    SoundController.playAudio(SOUND_LABELS.ABOUT_ACTION);

    gsap.delayedCall(1, () => {
      if (target === "about") {
        SoundController.fadeFromTo(SOUND_LABELS.MAIN, SOUND_LABELS.ABOUT);
      } else {
        SoundController.fadeFromTo(SOUND_LABELS.ABOUT, SOUND_LABELS.MAIN);
      }
    });
    
    EventsManager.instance.trigger(
      target === "about" ? APP_EVENTS.PANEL_EXPLODE : APP_EVENTS.PANEL_RETRACT
    );
    EventsManager.instance.trigger(
      target === "about" ? APP_EVENTS.HEAD_SHOW : APP_EVENTS.HEAD_HIDE
    );
  }

  return (
    <Styled.Wrapper {...loadingMotionProps}>
      <Marquee
        speed={isDesktopView ? 2 : 20}
        pauseOnHover={isDesktopView}
        gradient={true}
        gradientColor={colors.cherryPie}
        gradientWidth={isDesktop() ? 300 : 50}
      >
        <Styled.Link
          onClick={() =>
            updateUI(ROUTES.ABOUT, { previousPath: location.pathname }, "about")
          }
        >
          <Styled.Item
            data-text={"About"}
            $isActive={location.pathname === ROUTES.ABOUT}
          >
            About
          </Styled.Item>
        </Styled.Link>
        <Styled.Link
          onClick={() =>
            updateUI(
              ROUTES.HOME,
              { previousPath: location.pathname },
              "projects"
            )
          }
        >
          <Styled.Item
            data-text={"Projects"}
            $isActive={
              location.pathname === ROUTES.HOME ||
              location.pathname.includes(ROUTES.PROJECT)
            }
          >
            Projects
          </Styled.Item>
        </Styled.Link>
        <Styled.Link
          onClick={() =>
            updateUI(ROUTES.ABOUT, { previousPath: location.pathname }, "about")
          }
        >
          <Styled.Item
            data-text={"About"}
            $isActive={location.pathname === ROUTES.ABOUT}
          >
            About
          </Styled.Item>
        </Styled.Link>
        <Styled.Link
          onClick={() =>
            updateUI(
              ROUTES.HOME,
              { previousPath: location.pathname },
              "projects"
            )
          }
        >
          <Styled.Item
            data-text={"Projects"}
            $isActive={
              location.pathname === ROUTES.HOME ||
              location.pathname.includes(ROUTES.PROJECT)
            }
          >
            Projects
          </Styled.Item>
        </Styled.Link>
      </Marquee>
    </Styled.Wrapper>
  );
};


export default Menu;
