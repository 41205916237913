import React, { useEffect, useState } from 'react';

import * as Styled from './NavBar.styles';
import { PROJECT_IDS } from '../../data/content';
import useGlobalStore from '../../utils/store';
import { AnimatePresence } from 'framer-motion';
// import { APP_EVENTS, EventsManager } from '../../webgl/events/EventsManager';
import { SoundController } from "webgl/controllers/SoundController";
import { SOUND_LABELS } from "../../data/sound";

export interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = () => {
  const { projectID, setProjectID, showNavBar } = useGlobalStore();
  const [showBar, setShowBar] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowBar(showNavBar.visible);
    }, showNavBar.delay * 1000 );
  }, [showNavBar.delay, showNavBar.visible]);

  const goTo = (dir: number) => {
    let nextIndex = dir > 0 ? projectID + 1 : projectID - 1;
    if (nextIndex > PROJECT_IDS.work.length - 1) nextIndex = 0;
    if (nextIndex < 0) nextIndex = PROJECT_IDS.work.length - 1;
    setProjectID(nextIndex);
  }

  const getItems = () => {
    const items: React.JSX.Element[] = [];
    PROJECT_IDS.work.map((w, i) =>
      items.push(
        <Styled.Item
          initial={{ margin: "0" }}
          animate={{ margin: "0 10rem" }}
          exit={{ margin: "0" }}
          transition={{
            duration: 0.3,
            delay: 1,
          }}
          key={w.id}
          className={i === projectID ? "active" : ""}
          data-sfx={i}
          data-project={w.id}
          onClick={() => {
            setProjectID(i);
            if (!SoundController.muted.value) {
              SoundController.playAudioSprite(SOUND_LABELS.MENU, `${i + 1}`);
            }
            //  EventsManager.instance.trigger(APP_EVENTS.PANEL_UPDATE, {
            //    video: w.video,
            //  });
          }}
        />
      )
    );
    return items;
  };
  return (
    <AnimatePresence>
      {showBar && (
        <Styled.Wrapper
          initial={{ opacity: 0, scaleY: 0, x: "-50%" }}
          animate={{ opacity: 1, scaleY: 1, x: "-50%" }}
          exit={{ opacity: 0, scaleY: 0, x: "-50%" }}
          transition={{
            duration: 0.4,
          }}
        >
          <Styled.ArrowLeft onClick={() => goTo(-1)} />
          <Styled.List>{getItems()}</Styled.List>
          <Styled.ArrowRight onClick={() => goTo(1)} />
        </Styled.Wrapper>
      )}
    </AnimatePresence>
  );
};
export default NavBar;
