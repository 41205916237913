import styled, { css } from "styled-components";
import { mediaDesktop, mediaTablet } from "../../utils/styles/responsive";
import { colors, layout } from "../../utils/styles/theme";
import { motion } from "framer-motion";
import { rgba } from "polished";

export const Wrapper = styled(motion.div)`
  background-color: ${rgba(colors.cherryPie, 0.8)};
  width: 100vw;
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: ${layout.zIndex.ui};
  overflow: hidden;

  ${mediaTablet(`
    width: 100vh;
    height: 70rem;
    right: 70rem;
    bottom: -70rem;
    transform: rotate(-90deg) translateX(100%);
    transform-origin: top right;
  `)}

  > div {
    height: 100%;
  }

  > .rfm-overlay {
    opacity: 0.8;
  }
`;
export const Link = styled.div`
  cursor: pointer;
`;
export const Item = styled.span<{ $isActive: boolean }>`
  font-family: Archivo;
  letter-spacing: 2rem;
  font-size: 24rem;
  --bg-size: 400%;
  color: black;
  margin: 0 20rem;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1rem ${colors.electricViolet};
  text-transform: uppercase;
  position: relative;
  filter: drop-shadow(0 0 0rem white);
  transition: filter 0.2s linear;

  &::before {
    position: absolute;
    content: attr(data-text);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
        90deg,
        ${colors.grad2} 15%,
        ${colors.grad3} 40%,
        ${colors.grad3} 60%,
        ${colors.grad2} 85%
      )
      0 0 / var(--bg-size) 100%;
    opacity: 0;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0rem transparent;
    background-clip: text;
    animation: move-bg 15s linear infinite;
    transition: opacity 0.3s linear;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      letter-spacing: 1rem;
      -webkit-text-stroke: 0rem ${colors.electricViolet};
      filter: drop-shadow(0 0 1rem white);
      transition: opacity 1s linear, filter 0.8s 0.2s ease-out;

      ${mediaDesktop(`
        filter: drop-shadow(0 0 3rem white);
    `)}

      &::before {
        opacity: 1;
      }
    `};

  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }

  ${mediaDesktop(`
    font-size: 50rem;
    -webkit-text-stroke: 2rem ${colors.electricViolet};
  `)}
`;
