export const SOUND_LABELS = {
  MAIN: "main",
  ABOUT: "about",
  ABOUT_ACTION: "about_action_crop",
  ACTION: "action",
  EXPLODE: "explode",
  GATE: "gate_crop",
  MENU: "menu",
  MOUSE_OVER: "mouse_over",
};
