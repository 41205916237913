import gsap from "gsap";
import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Object3D,
  Points,
  ShaderMaterial,
  Vector3,
} from "three";
import { ScenesManager } from "../ScenesManager";
import { headFragment, headVertex } from "../shaders/head";
import { isDesktop } from "utils/platform";
import {
  ARM_X_DIST,
  ARM_X_MEAN,
  ARM_Y_DIST,
  ARM_Y_MEAN,
  ARMS,
  GALAXY_THICKNESS,
  gaussianRandom,
  spiral,
} from "utils/math";

export class Spiral extends Object3D {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  points:
    | {
        object: Points | undefined;
        surrounding: number[];
        shape: number[];
        around: number[];
        sizes: number[];
        colors: number[];
        pointSize: number;
        animation: {
          in: {
            lerpFactor: number;
          };
          out: {
            lerpFactor: number;
          };
        };
      }
    | undefined;
  transitioning = false;
  introDone = false;
  spiralTween!: gsap.core.Tween;
  constructor() {
    super();

    this.build();
  }

  build() {
    this.points = {
      object: undefined,
      surrounding: [],
      shape: [],
      around: [],
      sizes: [],
      colors: [],
      pointSize: isDesktop() ? 0.15 : 0.3,
      animation: {
        in: {
          lerpFactor: 0.03,
        },
        out: {
          lerpFactor: 0.01,
        },
      },
    };

    const color = new Color(0xffffff);
    const amount = 30000;
    const vertex = new Vector3();
    for (let i = 0; i < amount; i += 3) {
      let pos = spiral(
        gaussianRandom(ARM_X_MEAN, ARM_X_DIST),
        gaussianRandom(ARM_Y_MEAN, ARM_Y_DIST),
        gaussianRandom(0, GALAXY_THICKNESS),
        (i * 2 * Math.PI) / ARMS
      );
      vertex.set(pos.x, pos.y, pos.z);
      this.points.shape.push(vertex.x, vertex.y, vertex.z);
      pos = spiral(
        gaussianRandom(ARM_X_MEAN * 5, ARM_X_DIST * 5),
        gaussianRandom(ARM_Y_MEAN * 5, ARM_Y_DIST * 5),
        gaussianRandom(0, GALAXY_THICKNESS * 5),
        (i * 2 * Math.PI) / ARMS
      );
      this.points.surrounding.push(pos.x, pos.y, pos.z);

      color.toArray(this.points.colors, i * 3);

      this.points.sizes[i] =
        i % 10 === 0
          ? Math.random() * this.points.pointSize * 0.5
          : this.points.pointSize;
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute(
      "position",
      new Float32BufferAttribute(this.points.shape, 3)
    );
    geometry.setAttribute(
      "size",
      new Float32BufferAttribute(this.points.sizes, 1)
    );
    geometry.setAttribute(
      "customColor",
      new Float32BufferAttribute(this.points.colors, 3)
    );

    this.points.object = new Points(geometry, this.getMaterial());
    const scale = { value: 0.07 };
    this.points.object.rotation.set(-0.41, 0, 0);
    this.points.object.scale.set(scale.value, scale.value, scale.value);
    this.points.object.position.set(-0.16, 1.5, 0.43);

    if (ScenesManager.tweakPane) {
      const f1 = ScenesManager.tweakPane.addFolder({ title: "Spiral HEAD" });
      f1.addBinding(this.points.object.position, "x", {
        min: -10,
        max: 10,
      });
      f1.addBinding(this.points.object.position, "y", {
        min: -10,
        max: 10,
      });
      f1.addBinding(this.points.object.position, "z", {
        min: -10,
        max: 10,
      });
      f1.addBinding(this.points.object.rotation, "x", {
        min: -Math.PI,
        max: Math.PI,
        label: "rotation",
      });

      f1?.addBinding(scale, "value", {
        min: 0.0,
        max: 2.0,
        label: "scale",
      }).on("change", (ev: { value: number }) => {
        this.points?.object?.scale.set(ev.value, ev.value, ev.value);
      });

      f1.addBinding(
        (this.points.object.material as ShaderMaterial).uniforms.uOpacity,
        "value",
        {
          label: "opacity",
          min: 0.0,
          max: 1.0,
        }
      );
    }

    this.add(this.points.object);
  }

  getMaterial() {
    const color = new Color(0xffffff);
    const material = new ShaderMaterial({
      transparent: true,
      blending: AdditiveBlending,
      depthTest: false,
      uniforms: {
        uOpacity: { value: 0.0 },
        uTime: { value: 0.0 },
        color: { value: color },
        pointTexture: { value: ScenesManager.assets.star },
      },
      vertexShader: headVertex,
      fragmentShader: headFragment,
    });

    return material;
  }

  show() {
    return new Promise((resolve) => {
      if (this.points && this.points.object) {
        this.spiralTween?.kill();
        this.spiralTween = gsap.to(
          (this.points.object.material as ShaderMaterial).uniforms.uOpacity,
          {
            value: isDesktop() ? 0.1 : 0.01,
            duration: 1,
            delay: 2.2,
            onStart: () => {
              this.animateIn();
              resolve(true);
            },
          }
        );
      }
    });
  }

  animateIn() {
    if (this.points) {
      gsap.fromTo(
        this.points.animation.in,
        { lerpFactor: 0 },
        {
          lerpFactor: 0.05,
          ease: "power4.inOut",
          duration: 1,
          onStart: () => {
            this.transitioning = true;
          },
          onComplete: () => {
            this.introDone = true;
          },
        }
      );
    }
  }

  hide() {
    return new Promise((resolve) => {
      if (this.points && this.points.object) {
        const material = this.points.object.material as ShaderMaterial;
        this.spiralTween?.kill();
        this.spiralTween = gsap.to(material.uniforms.uOpacity, {
          value: 0.0,
          duration: 0.3,
        });
        this.transitioning = false;
        this.introDone = false;
      }
      resolve(true);
    });
  }

  animate() {
    if (this.points && this.points.object && this.transitioning) {
      this.points.object.rotation.z -= 0.005;
    }
  }
}
