import React from 'react';

import * as Styled from './ButtonBack.styles';
import { colors } from 'utils/styles/theme';

export interface ButtonBackProps {
  onAction: () => void;
  $isVisible: boolean;
}

const ButtonBack: React.FC<ButtonBackProps> = ({ onAction, $isVisible }) => {
  return (
    <Styled.Wrapper onClick={onAction} className={$isVisible ? "show" : ""} disabled={!$isVisible}>
      <svg
        className="page__back-button__shape"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 121.02 120.89"
      >
        <g>
          <g>
            <polygon
              className="next-bg"
              points="39.83 0 121.02 0 121.02 80.43 80.55 120.89 0 40.34 39.83 0"
            />
            <polyline
              className="next-line"
              points="82.3 65.19 98 80.89 82.43 96.47"
            />
          </g>
        </g>
      </svg>
      <Styled.Text
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 121.02 120.89"
      >
        <mask id="backButtonMask">
          <path d="M39.83,0H121V80.43L80.55,120.89,0,40.34Z" />
        </mask>
        <text rotate="180" x="0" y="0" mask="url(#backButtonMask)" fill={colors.white}>
          <animate
            attributeType="transform"
            attributeName="dx"
            from="-50%"
            to="120%"
            dur="4s"
            repeatCount="indefinite"
          />
          <tspan x="0" y="12.5%">
            KCAB
            {/* STCEJORP OT KCAB */}
          </tspan>
        </text>
      </Styled.Text>
    </Styled.Wrapper>
  );
};

export default ButtonBack;
