import { Vector3 } from "three";

export const ease = (current: number, target: number, time: number) => {
  return (1 - time) * current + time * target;
};

export const lerp = (start: number, end: number, amt: number) => {
  return (1 - amt) * start + amt * end;
};

export const map = (
  value: number,
  low1: number,
  high1: number,
  low2: number,
  high2: number
) => {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
};

export const clamp = (num: number, min: number, max: number) => {
  return Math.min(Math.max(num, min), max);
};

export const radiansToDegrees = (radians: number) => {
  return radians * (180 / Math.PI);
};

export const magSq = (v: { x: number; y: number; z: number }) => {
  return Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
};

export const getRandomInRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export function gaussianRandom(mean = 0, stdev = 1) {
  let u = 1 - Math.random();
  let v = Math.random();
  let z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

  return z * stdev + mean;
}

export const GALAXY_THICKNESS = 0.5;
export const ARM_X_DIST = 5;
export const ARM_Y_DIST = 0.25;
export const ARM_X_MEAN = 5;
export const ARM_Y_MEAN = 0.5;

export const SPIRAL = 10.0;
export const ARMS = 1.0;

export function spiral(x: number, y: number, z: any, offset: any) {
  let r = Math.sqrt(x ** 2 + y ** 2);
  let theta = offset;
  theta += x > 0 ? Math.atan(y / x) : Math.atan(y / x) + Math.PI;
  theta += (r / ARM_X_DIST) * SPIRAL;
  return new Vector3(r * Math.cos(theta), r * Math.sin(theta), z);
}
