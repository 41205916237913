import styled from "styled-components";
import { EASE_FUNC } from "../../utils/styles/animations";
import { colors, layout } from "../../utils/styles/theme";
import { mediaMobile } from "../../utils/styles/responsive";

export const Wrapper = styled.div`
  width: 90rem;
  height: auto;
  position: absolute;
  right: 9vw;
  top: ${layout.appSizes.margin};
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  z-index: ${layout.zIndex.ui};
  filter: drop-shadow(0 0 10rem black);

  svg {
    pointer-events: none;
    .bg-fill {
      fill: ${colors.cherryPie};
    }
    .bar-fill {
      fill: ${colors.electricViolet};
      transform-origin: 50%;
      transition: transform 0.6s ${EASE_FUNC.sineInOut},
        fill 0.6s ${EASE_FUNC.sineInOut};
    }
  }

  ${mediaMobile(`
    right: ${layout.appSizes.margin};
    width: 60rem;
`)}

  &.show {
    opacity: 1 !important;
    visibility: visible;
    transition: opacity 0.6s 1s ${EASE_FUNC.sineInOut};
  }

  &.playing {
    svg {
      .bar-fill {
        animation: grafic 0.4s linear infinite alternate;
        fill: ${colors.brightTurquoise};
      }
      .bar-fill:nth-child(1) {
        animation-delay: 0.3s;
      }

      .bar-fill:nth-child(2) {
        animation-duration: 0.7s;
      }

      .bar-fill:nth-child(3) {
        animation-delay: 0.8s;
      }

      .bar-fill:nth-child(4) {
        animation-delay: 0.4s;
      }

      .bar-fill:nth-child(5) {
        animation-duration: 0.6s;
      }
    }
  }

  @keyframes grafic {
    0% {
      transform: scaleY(0.6);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;
