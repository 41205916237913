import React from 'react';

import * as Styled from './ButtonCta.styles';

export interface ButtonCtaProps {
  onAction: () => void;
  label: string;
  onDisabled: boolean;
}

const ButtonCta: React.FC<ButtonCtaProps> = ({
  onAction,
  label,
  onDisabled,
}) => {
  return (
    <Styled.Wrapper onClick={onAction}>
      <Styled.Button disabled={onDisabled}>{label}</Styled.Button>
    </Styled.Wrapper>
  );
};

export default ButtonCta;
