export const particlesVertex = /* glsl */ `
    uniform sampler2D uPositions;//RenderTarget containing the transformed positions
    uniform float uSize;
    uniform float uPixelRatio;
    uniform vec2 uResolution;
    uniform float uScroll;
    varying vec3 vPos;
    varying vec2 vUv;
    uniform float uTime;
    void main() {
        //the mesh is a nomrliazed square so the uvs = the xy positions of the vertices
        vec3 pos = texture2D( uPositions, position.xy ).xyz;
        //pos now contains a 3D position in space, we can use it as a regular vertex

        float range = 1.0 / 3.0;
        float customSize = uSize;

        vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;


        if (uScroll < range) {
            customSize = mix(7.0, 10., uScroll * 3.0);
        } else if (uScroll < range * 2.0) {
            customSize = mix(10., 5., (uScroll - range) * 3.0);
        } else if (uScroll < range * 3.0) {
            customSize = mix(5., 20., (uScroll - range * 2.0) * 3.0);
        } else {
            float scroll = max((uScroll - range * 3.0), (uScroll - range * 3.0) * 3.0);
            customSize = mix(20., 15., scroll);
        }

        float freq = 500.0;
        gl_Position = projectionPosition;
        gl_PointSize = customSize * uResolution.y * 0.002 * sin(freq * modelPosition.z + uTime);
        // gl_PointSize = customSize * uResolution.y * 0.0013;
        gl_PointSize *= (1.0 / - viewPosition.z);
        // gl_PointSize *= customSize + sin(freq * modelPosition.z + (uTime + 0.01));

        vPos = pos;
    }

`;
export const particlesFragment = /* glsl */ `
    varying vec3 vPos;
    uniform vec3 uColor;
    uniform float uRange;
    uniform float uTime;
    uniform float uScroll;
    uniform float uOpacity;
    void main()
    {
        //  if ( vPos == vec3(0.0) )
        //  {
        //    discard;
        //  }

        float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
        float strength = 0.05 / distanceToCenter - 0.1;

        vec3 color = vec3(uColor.x, uColor.y, uColor.z);
        float freq = 10.0;
        
        float customAlpha = strength * length(vPos) * uOpacity;
        // customAlpha += sin(freq * vPos.z + uTime);
        
        // if (uScroll < uRange) {
        //     color = mix(color, vec3(1.0, 0.1, 0.0), uScroll * 3.0 );
        // } else if (uScroll < uRange * 2.0) {
        //     color = mix(vec3(1.0, 0.1, 0.0), vec3(1.0, 0.5, 0.0), (uScroll - uRange) * 3.0 );
        // } else {
            //color =  vec3(1.0, 0.5, 0.0);
        // }

        // set FireFilies orange Color
        gl_FragColor = vec4(color, customAlpha);
    }
`;
