import gsap from "gsap";
import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  Points,
  ShaderMaterial,
  Vector3,
} from "three";
import { ScenesManager } from "../ScenesManager";
import { headFragment, headVertex } from "../shaders/head";
import { isDesktop } from "utils/platform";
import { delay } from "utils/time";

// https:codepen.io/prisoner849/pen/yLGGKqo?editors=0010
// https://codepen.io/prisoner849/pen/zYRjBWN?editors=0010

export class Head extends Object3D {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  head: Object3D | undefined;
  gradientPlane: Mesh | undefined;
  points:
    | {
        object: Points | undefined;
        surrounding: number[];
        shape: number[];
        around: number[];
        sizes: number[];
        colors: number[];
        pointSize: number;
        animation: {
          in: {
            lerpFactor: number;
          };
          out: {
            lerpFactor: number;
          };
        };
      }
    | undefined;
  transitioning = false;
  introDone = false;
  constructor() {
    super();

    this.build();
  }

  build() {
    this.head = ScenesManager.assets.head.scene;
    this.points = {
      object: undefined,
      surrounding: [],
      shape: [],
      around: [],
      sizes: [],
      colors: [],
      pointSize: 0.15,
      animation: {
        in: {
          lerpFactor: 0.03,
        },
        out: {
          lerpFactor: 0.01,
        },
      },
    };
    if (this.head && this.head.children) {
      const position = (this.head.children[0] as Mesh).geometry.attributes
        .position;
      const color = new Color(isDesktop() ? 0xffffff : 0xff00ff);
      const amount = position.array.length;
      const vertex = new Vector3();
      let x = 0;
      let y = 0;
      let z = 0;
      const moduleOp = isDesktop() ? 1 : 2;
      for (let i = 0; i < amount; i += 3) {
        if (i % moduleOp === 0) {
          vertex.x = position.array[i + 0];
          vertex.y = position.array[i + 1];
          vertex.z = position.array[i + 2];
          this.points.shape.push(vertex.x, vertex.y, vertex.z);

          x = Math.random() * 140 - 70;
          y = Math.random() * 140 - 70;
          z = Math.random() * 140 - 70;
          this.points.surrounding.push(x, y, z);

          color.toArray(this.points.colors, i * 3);

          this.points.sizes[i] =
            i % 10 === 0
              ? Math.random() * this.points.pointSize * 0.5
              : this.points.pointSize;
        }
      }

      const geometry = new BufferGeometry();
      geometry.setAttribute(
        "position",
        new Float32BufferAttribute(this.points.surrounding, 3)
      );
      geometry.setAttribute(
        "size",
        new Float32BufferAttribute(this.points.sizes, 1)
      );
      geometry.setAttribute(
        "customColor",
        new Float32BufferAttribute(this.points.colors, 3)
      );

      this.points.object = new Points(geometry, this.getMaterial());
      this.points.object.rotation.set(Math.PI / 2, 0, 0);
      this.points.object.scale.set(0.15, 0.15, 0.15);
      this.points.object.position.set(
        isDesktop() ? -0.15 : 0,
        isDesktop() ? -0.4 : -0.25,
        isDesktop() ? 0.5 : -0.6
      );

      this.add(this.points.object);

      const gradientMaterial = new MeshBasicMaterial({
        map: ScenesManager.assets.headGradient,
        alphaMap: ScenesManager.assets.headGradient,
        transparent: true,
        color: new Color(0x000000),
        opacity: 0,
      });
      this.gradientPlane = new Mesh(new PlaneGeometry(5, 5), gradientMaterial);
      this.gradientPlane.position.set(-0.16, 0, -0.87);
      this.gradientPlane.scale.set(1.8, 2.99, 2.99);
      this.add(this.gradientPlane);
      if (ScenesManager.tweakPane) {
        const f1 = ScenesManager.tweakPane.addFolder({
          title: "Gradientt HEAD",
        });
        f1.addBinding(this.gradientPlane.position, "x", {
          min: -10,
          max: 10,
        });
        f1.addBinding(this.gradientPlane.position, "y", {
          min: -10,
          max: 10,
        });
        f1.addBinding(this.gradientPlane.position, "z", {
          min: -10,
          max: 10,
        });
        f1.addBinding(gradientMaterial, "opacity", {
          min: 0,
          max: 1,
        });
        const scale = { value: 0.07 };
        f1?.addBinding(scale, "value", {
          min: 0.0,
          max: 5.0,
          label: "scale",
        }).on("change", (ev: { value: number }) => {
          if (this.gradientPlane) {
            this.gradientPlane.scale.set(ev.value, ev.value, ev.value);
          }
        });
      }
    }
  }

  getMaterial() {
    const color = new Color(isDesktop() ? 0xffffff : 0xff00ff);
    const material = new ShaderMaterial({
      transparent: true,
      blending: AdditiveBlending,
      depthTest: false,
      uniforms: {
        uOpacity: { value: 0.0 },
        uTime: { value: 0.0 },
        color: { value: color },
        pointTexture: { value: ScenesManager.assets.star },
      },
      vertexShader: headVertex,
      fragmentShader: headFragment,
    });

    return material;
  }

  show() {
    return new Promise((resolve) => {
      if (this.points && this.points.object) {
        gsap.to(
          (this.points.object.material as ShaderMaterial).uniforms.uOpacity,
          {
            value: 1.0,
            duration: 1,
            delay: 0.8,
            onStart: () => {
              this.animateIn();
              resolve(true);
            },
          }
        );
      }
      if (this.gradientPlane) {
        gsap.to(this.gradientPlane.material, {
          opacity: 1.0,
          duration: 1,
          delay: 0.8,
        });
      }
    });
  }

  animateIn() {
    if (this.points) {
      gsap.fromTo(
        this.points.animation.in,
        { lerpFactor: 0 },
        {
          lerpFactor: isDesktop() ? 0.03 : 0.1,
          ease: "power4.inOut",
          duration: isDesktop() ? 1 : 0.5,
          onStart: () => {
            this.transitioning = true;
          },
          onComplete: () => {
            this.introDone = true;
            ScenesManager.followMouseEnabled = true;
          },
        }
      );
    }
  }

  hide() {
    return new Promise(async (resolve) => {
      if (this.points && this.points.object) {
        const material = this.points.object.material as ShaderMaterial;
        gsap.to(material.uniforms.uOpacity, {
          value: 0.0,
          duration: 1,
          delay: 0.5,
        });
        this.transitioning = false;
        this.introDone = false;
        ScenesManager.followMouseEnabled = false;
      }
      if (this.gradientPlane) {
        gsap.to(this.gradientPlane.material, {
          opacity: 0.0,
          duration: 1,
        });
      }
      await delay(2);
      resolve(true);
    });
  }

  animate() {
    const timeShader = performance.now() * 0.005;
    if (this.points && this.points.object) {
      const attrs = this.points.object.geometry.attributes;
      const position = attrs.position;
      const target = this.transitioning
        ? this.points.shape
        : this.points.surrounding;
      const lerpFactor =
        this.points.animation[this.transitioning ? "in" : "out"].lerpFactor;
      for (let i = 0; i < position.array.length; i += 3) {
        const origin = new Vector3(
          position.array[i],
          position.array[i + 1],
          position.array[i + 2]
        );
        const dest = new Vector3(target[i], target[i + 1], target[i + 2]);
        origin.lerp(dest, lerpFactor);
        position.array[i] = origin.x;
        position.array[i + 1] = origin.y;
        position.array[i + 2] = origin.z;
      }
      position.needsUpdate = true;

      (this.points.object.material as ShaderMaterial).uniforms.uTime.value =
        timeShader;
    }
  }
}
