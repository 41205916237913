import { isBrowser } from "utils/platform";
import { VIDEO_IDS } from "./panel";

const getPath = () => {
  return isBrowser() ? window.location.origin : "";
};

export const PROJECT_IDS = {
  work: [
    {
      id: "dreamworld",
      path: "dreamworld",
      video: VIDEO_IDS.dreamworld,
      title: "COCA-COLA: Dreamworld AR",
      techs: "WEBGL THREEJS 8THWALL GLSL GSAP REACT 3D",
      content: [
        {
          key: "dreamworld1",
          title: "THE EXECUTION",
          description: `
            The whole WebGL experience was developed with three.js, GLSL and 8thWall, with exciting techniques to build this surreal world.
            <br/><br/>
            I've implemented all of the 3D experience features including the Three.js Architecture, 8th Wall Postal using SLAM, Grass Shader, Sky Shader, Morph Shaders, DJ booth shader with Volumetric Clouds Shaders, Particles System, post-processing pipeline, among many others.`,
          video: `${getPath()}/assets/works/dreamworld/clip4.mp4`,
        },
        {
          key: "dreamworld2",
          title: "THE CHALLENGE",
          description: `
            It was an exclusive megamix from top Tomorrowland music festival DJs that users could take part in a rhythm game within the experience, constructing the world around them and discovering hidden surprises as they hit the beats in time to the music.
            <br/>
            All of the elements in the world was Audio Reactive. Yes, all the elements!
            <br/><br/>            
            There was no shortage of challenges here.                       
          `,
          image: `${getPath()}/assets/works/dreamworld/coke-ar-dreamworld-header-final-1400x788.png`,
        },
        {
          key: "dreamworld3",
          description: `
            I could talk about each implementation here, but in addition to achieving satisfactory visual results and performance for each of the different shaders and behaviors, I needed to achieve this result by bringing all these elements together.
            <br/><br/>
            In this project we had a sky with refraction and reflection, like water.
             <br/><br/>
            Grass all over the territory that changed color depending on the user's evolution in the experience and incidence of wind.
             <br/><br/>
            Volumetric cloud surrounding the DJ booth, DJ who has different behaviors and dances.
             <br/><br/>
            Easter eggs scattered throughout the world of the most varied, from simple meshes with color changes to reactive metaballs.
             <br/><br/>
            In addition to all this and a few other things not mentioned, a beatsaber style game, with the targets coming from the DJ towards the user.
            Game that was developed by the incredible developer Dobi Okrasa, from Poland.
            <br/><br/>
            Seriously, it was huge! One of the biggest projects I've had the pleasure of executing and I'm very proud of the result!              
          `,
          video: `${getPath()}/assets/works/dreamworld/aug-15-2022-11-11-20.mp4`,
        },
        {
          key: "dreamworld4",
          video: `${getPath()}/assets/works/dreamworld/aug-15-2022-11-13-27.mp4`,
        },
        {
          key: "dreamworld5",
          title: "PROJECT INFO",
          description: `
            <b>Technology</b><br/>
            WebGL, 8th Wall, 3D, React, Typescript, GSAP, GLSL<br/><br/>

            <b>Agency</b><br/>
            Unit9<br/><br/>
            
            <b>Brand</b><br/>
            Coca-Cola Company<br/><br/>
                       
            <b>Platform</b><br/>
            Desktop and Mobile<br/><br/>
            
            <b>Awards</b><br/>
            FWA x1
          `,
        },
      ],
    },
    {
      id: "wristbands",
      path: "wristbands",
      video: VIDEO_IDS.wristbands,
      title: "COCA-COLA: ENDLESS CATCHER",
      techs: "WEBGL THREEJS GLSL GSAP REACT 3D",
      content: [
        {
          key: "wristbands1",
          title: "THE EXECUTION",
          description: `
            The whole WebGL game was developed with three.js and GLSL, with exciting techniques to build this beautiful one.
            <br/><br/>
            I've implemented all of the game features including the Three.js Architecture, Game logic, dynamic bezier curves, bend shader with physics, post-processing pipeline, among others.`,
          video: `${getPath()}/assets/works/wristbands/${
            VIDEO_IDS.wristbands
          }.mp4`,
        },
        {
          key: "wristbands2",
          title: "THE CHALLENGE",
          description: `
            I had two major challenges here: natural motion and performance.
            <br/><br/>
            Using dynamic bezier curves from points A to B then passing those paths to the vertex shader, I could update all the elements in a single draw call.
            Resulting is this smoothing motion simulating physics to have this natural motion result. This was a major secondary challenge.
            <br/><br/>
            Another detail that consumed some time was being able to render the different states of the wristbands in the same calculation, because in the initial state, the wristband is flying, however, once it is captured, it floats to the arm and stays there like a snake.
            <br/><br/>
            So, I had a single mesh of the wristband with an array of textures to render different colors and patterns using the InstacedMesh approach, with dynamic buffer, and all the motion calculations and physics were applied in the vertex shader. 
            <br/><br/>
            So I could solve all of the game in one custom shader running at 60fps on every device.  
          `,
        },
        {
          key: "wristbands3",
          title: "PROJECT INFO",
          description: `
            <b>Technology</b><br/>
            WebGL, 3D, React, Typescript, GSAP, GLSL<br/><br/>

            <b>Agency</b><br/>
            Unit9<br/><br/>
            
            <b>Brand</b><br/>
            Coca-Cola Company<br/><br/>
                       
            <b>Platform</b><br/>
            Mobile<br/><br/>
          `,
        },
      ],
    },

    {
      id: "lcdp",
      path: "lcdp",
      video: VIDEO_IDS.lcdp,
      title: "NETFLIX: LA CASA DE PAPEL",
      techs: "WEBGL THREEJS GLSL GSAP REACT NODE.JS 3D",
      content: [
        {
          key: "lcdp1",
          title: "THE EXECUTION",
          description: `
            The whole WebGL experience was developed with three.js and GLSL, with exciting techniques to build this beautiful globe with 21k of the resolution containing many cool interactions.
            <br/><br/>
            I've implemented all of the WebGL features of the experience including the Globe from 64 depth map slices, camera animations, distance coloring shader, markers with cluster logic to group Fans messages in the map using lat and long coordinates, post-processing pipeline, among others.`,
          video: `${getPath()}/assets/works/lcdp/${VIDEO_IDS.lcdp}.mp4`,
        },
        {
          key: "lcdp2",
          title: "THE CHALLENGE",
          description: `
          The biggest challenge was rendering a 3D globe with terrain elevation and 21k resolution.
          <br/><br/>
          Since we had different zoom levels to reveal different content, the highest zoom level was very close to the camera, where we had some 3D sculptures, country and city names, and user messages precisely located on the map based on where they were sent using longitude and latitude.
          To render this level of detail, I used a depth map of the globe and sliced ​​it into 64 slices to enclose a sphere.
          <br/><br/>
          The creative team used the same depth map to generate the base color map.
          <br/><br/>
          However, due to performance issues, it was not feasible to use the entire globe at 21k in one view, especially on mobile.
          <br/>
          That's when I split the 64 textures into 3 quality levels and created a custom LOD (Level Of Detail) logic, thus generating 192 globe textures. Based on the zoom level and map coordinates, specific textures were loaded to show only what was needed and not consume too much memory.
          <br/><br/>
          Like map textures, 3D models were loaded into a priority queue and presented to the user on camera demand.
          <br/><br/>
          The result was stunning visuals with incredible performance.  
          `,
        },
        {
          key: "lcdp3",
          title: "PROJECT INFO",
          description: `
            <b>Technology</b><br/>
            WebGL, 3D, Amazon Web Services, Google APIs, React, Typescript, Node.js<br/><br/>

            <b>Agency</b><br/>
            Unit9<br/><br/>
            
            <b>Brand</b><br/>
            Netflix<br/><br/>
            
            <b>3D Design</b><br/>
            HKI<br/><br/>
            
            <b>Platform</b><br/>
            Desktop and Mobile<br/><br/>
            
            <b>Awards</b><br/>
            FWA x1
          `,
        },
      ],
    },
    {
      id: "beefeater",
      path: "beefeater",
      video: VIDEO_IDS.beefeater,
      title: "Beefeater - The Spirit Of London",
      techs:
        "WEBGL 3D THREE.JS WEBAR SKY-SEGMENTATION 8THWALL GSAP REACT TYPESCRIPT",
      content: [
        // {
        //   title: "BACKGROUND",
        //   description: `This digital-first experience demonstrates how IBM can help businesses harness the full power of their IT infrastructure`,
        // },
        {
          key: "beefeater1",
          title: "THE EXECUTION",
          description: `I developed the entire WebGL experience with three.js and 8thWall including WebGL project architecture, custom Sky Segmentation pipeline that allowed me to work with alpha compositing without any stutters or FPS drops, huge garbage collector logic, Fake Bloom effect, custom Chromakey shader, post-processing pipeline, among others.`,
          video: `${getPath()}/assets/works/beefeater/${
            VIDEO_IDS.beefeater
          }.mp4`,
        },
        {
          key: "beefeater2",
          title: "THE CHALLENGE",
          description: `
            The big challenge here was to fit as many elements into the scene with performance along with vibrant visuals and animations.<br/>
            We had 3 large scenes in this experience.
            <br/><br/>
            To achieve this, some combination of layered 2D and 3D assets were used, such as low-poly 3D models with baked textures and chromakey video.
            <br/>
            I then built the compositing of each scene using layers with a custom chromakey shader combined with the 3Ds, where there was a lot of optimization work between myself and the modeling team to achieve lightweight but beautiful models with fluid animations.
            <br/><br/>
            Some animations were built in code from the particle system such as confetti explosions, water bubbles, flying bricks, etc.
            <br/><br/>
            An interesting point is the post-processing pipeline, which had to be developed very carefully to run throughout the gameplay.<br/>
            The bloom effect, for example, is not a Bloom effect. I used two layers of the same texture with blend mode and blur options in the fragment shader, simulating a Bloom effect, but very light.
            <br/>
            Better known as FakeBloom.
            <br/><br/>
            It is worth mentioning the intense work of cleaning memory between each scene that was done during the transitions of each station, which was done partially, since the user could at any time return to a previously seen scene, so nothing could be completely destroyed due to the cost of submitting the GPU again.
            <br/><br/>
            This was a rewarding project!                
          `,
        },
        {
          key: "beefeater3",
          title: "PROJECT INFO",
          description: `
            <b>Technology</b><br/>
            WebGL, 3D, Three.js, GLSL, 8thWall, SKY SEGMENTATION, GSAP, React, Typescript<br/><br/>

            <b>Agency</b><br/>
            Unit9<br/><br/>
            
            <b>Brand</b><br/>
            Beefeater<br/><br/>
            
            <b>Platform</b><br/>
            Mobile<br/><br/>
          `,
        },
      ],
    },
    {
      id: "ccxp",
      path: "ccxp",
      video: VIDEO_IDS.ccxp,
      title: "CCXP - Comic-Con 2020",
      techs: "WEBGL THREEJS GLSL GSAP",
      content: [
        {
          key: "ccxp1",
          title: "THE EXECUTION",
          description: `
            I developed the entire WebGL experience with three.js and GLSL.
            <br/><br/>
            I've implemented all of the WebGL features of the experience including the 3D islands world, volumetric lights, particle system, camera animations, 3D animations, custom GLSL shaders, post-processing pipeline, among others.`,
          video: `${getPath()}/assets/works/ccxp/${VIDEO_IDS.ccxp}.mp4`,
        },
        {
          key: "ccxp2",
          title: "THE CHALLENGE",
          description: `
            Performance was a strong point here.<br/>
            In addition to having 9 islands that make up the CCXP world, this project would be accessible by a wide range of devices, on mobile and desktop.
            <br/><br/>
            The big challenge here was to place so many elements in the scene with performance along with stunning visuals and animations.
            <br/>
            To achieve this, some combinations of 2D and 3D assets separated into layers were used, such as low-poly 3D models with baked textures and transparent textures to generate all the volumetric and neon lights.
            <br/><br/>
            For performance reasons, this project has no lights!
            <br/>
            Yes, no Spot, Directional, or any light!
            <br/>
            Textures were used for all the lighting in blending mode combined with the baked textures.
            <br/><br/>
            We used 9 separate models, with each island in two levels of detail, the well-known LOD (Level Of Detail), so I first loaded the entire low-poly scene to present the world quickly to the user, and based on the camera focus I prioritized loading the most detailed versions.
            <br/><br/>
            The social media icons seen in the video were a particle system with the number of user reactions in real time on CCXP's social media during the event.
            <br/>
            There was a lot of optimization work here to achieve fast updates through API requests without creating bottlenecks and then submitting the values ​​to the shader.
            <br/><br/>
            I'm proud to have been part of this!
          `,
        },
        {
          key: "ccxp3",
          title: "PROJECT INFO",
          description: `
            <b>Technology</b><br/>
            WebGL, 3D, GLSL, GSAP, Typescript<br/><br/>

            <b>Agency</b><br/>
            Vetor Zero / Vetor Lab<br/><br/>
            
            <b>Brand</b><br/>
            CCXP Comic-Con<br/><br/>
            
            <b>Platform</b><br/>
            Desktop and Mobile<br/><br/>
          `,
        },
      ],
    },
    {
      id: "fiatstrada",
      path: "fiatstrada",
      video: VIDEO_IDS.fiatstrada,
      title: "FIAT - STRADA WEBAR",
      techs: "WEBGL THREEJS WEBAR 8THWALL GLSL GSAP 3D",
      content: [
        {
          key: "fiatstrada1",
          title: "THE EXECUTION",
          description: `
            I developed part of the WebGL experience alongside a great developer and my friend Tiago Canzian using three.js and 8thWall.
            <br/><br/>
            I've worked in features like WebGL project architecture, 8thWall SLAM setup, some custom GLSL shaders like Carpaint, Drive Controls, Animation controllers, among others.`,
          video: `${getPath()}/assets/works/fiatstrada/${
            VIDEO_IDS.fiatstrada
          }.mp4`,
        },
        {
          key: "fiatstrada2",
          title: "THE CHALLENGE",
          description: `
            First, we had a big challenge with the 3D guys, to do a retopology on the original model of the car received from the Fiat factory, this model contained more than 2M polygons.
            <br/><br/>
            The final result was 200 thousand polygons, which created great challenges for us, however, visual fidelity was something crucial for the brand.
            <br/>
            In addition, color fidelity was even more important, that's when I created a car paint shader following strict guidelines when the paint layers were three, plus a layer of Flake for the metallic colors and two layers of varnish.
            <br/><br/>
            Another challenge was the insertion of some elements in the car body that were selected based on everyday uses.
            <br/>
            For those, we used low-poly models with baked textures and only a small incidence of direct light due to the camera movement. To make it a little more realistic.
            <br/><br/>
            Regarding steering controls, we ended up programming all the traction, acceleration, etc. physics from scratch. 
            <br/>
            The final build was a challenge since we had so many things to load and process, we didn't need more unnecessary weight and things that we ended up not using when we imported a library.
            <br/><br/>
            Finally, after overcoming all the challenges by combining many optimization techniques with custom shaders, we managed to achieve a visual result that was faithful to the brand and high-performance. 
            <br/>
            Even though 8thWall's recommendation was to use a maximum of 35k poly in the scene, we were able to go a little beyond the limits by using a total of 280k poly, considering 200k for the car plus 80k for all the other elements.
            <br/><br/>
            What a project!
          `,
        },
        {
          key: "ccxp3",
          title: "PROJECT INFO",
          description: `
            <b>Technology</b><br/>
            WebGL, 3D, Three.js, 8thWall, GLSL, GSAP<br/><br/>

            <b>Agency</b><br/>
            ARKx<br/><br/>
            
            <b>Brand</b><br/>
            Fiat<br/><br/>
            
            <b>Platform</b><br/>
            Mobile<br/><br/>
          `,
        },
      ],
    },
    {
      id: "globo",
      path: "globo",
      video: VIDEO_IDS.globo,
      title: "Globo - BRAND BOOK",
      techs: "WEBGL SVG Three.js GLSL GSAP",
      content: [
        {
          key: "globo1",
          title: "THE EXECUTION",
          description: ` I implemented part of the WebGL and UI and acted as Tech Lead.</br>
            My responsibilities were to manage and review code in a team of 4 Creative Developers, including me.</br></br>
            I've developed fronted and WebGL project architecture, some of the WebGL features, smart preloader logic, most of the UI, some of the mini-games, and mosaic galleries. 
            Besides that, I have integrated all of the work from the team during development and performance optimizations.`,
          video: `${getPath()}/assets/works/globo/globo-intro.mp4`,
        },
        {
          key: "globo2",
          video: `${getPath()}/assets/works/globo/globo2.mp4`,
        },
        {
          key: "globo3",
          video: `${getPath()}/assets/works/globo/globo3.mp4`,
        },
        {
          key: "globo4",
          video: `${getPath()}/assets/works/globo/globo4.mp4`,
        },
        {
          key: "globo5",
          title: "PROJECT INFO",
          description: `
            <b>Technology</b><br/>
            WEBGL SVG Three.js GLSL GSAP<br/><br/>

            <b>Agency</b><br/>
            Vetor Zero / Vetor Lab<br/><br/>
            
            <b>Brand</b><br/>
            GLOBO<br/><br/>
            
            <b>Platform</b><br/>
            Desktop and Mobile<br/><br/>
          `,
        },
      ],
    },
  ],
  // {
  //   id: "ibm",
  //   path: "ibm",
  //   video: VIDEO_IDS.ibm,
  //   title: "IBM - Backbone and Brains",
  //   techs: "WEBGL 3D THREE.JS GLSL GSAP REACT",
  //   content: [
  //     // {
  //     //   title: "BACKGROUND",
  //     //   description: `This digital-first experience demonstrates how IBM can help businesses harness the full power of their IT infrastructure`,
  //     // },
  //     {
  //       key: "ibm1",
  //       title: "THE EXECUTION",
  //       description: `I've implemented part of the WebGL features including WebGL project architecture, Gem's shader with custom morph and animation states, volumetric light shader, Gem's physics, 2 of the 3 games, and part of the post-processing pipeline, among others.`,
  //       video: `${getPath()}/assets/works/ibm/${VIDEO_IDS.ibm}.mp4`,
  //     },
  //     {
  //       key: "ibm2",
  //       video: `${getPath()}/assets/works/ibm/ibm-game1.mp4`,
  //     },
  //     {
  //       key: "ibm3",
  //       video: `${getPath()}/assets/works/ibm/ibm-game2.mp4`,
  //     },
  //     {
  //       key: "ibm4",
  //       title: "PROJECT INFO",
  //       description: `
  //         <b>Technology</b><br/>
  //         WebGL, 3D, Three.js, GLSL, GSAP, React, Typescript, Strapi<br/><br/>

  //         <b>Agency</b><br/>
  //         Le Polish Bureau<br/><br/>

  //         <b>Brand</b><br/>
  //         IBM<br/><br/>

  //         <b>Platform</b><br/>
  //         Desktop and Mobile<br/><br/>

  //         <b>Awards</b><br/>
  //         FWA x1
  //       `,
  //     },
  //   ],
  // },
};
