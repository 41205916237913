import EventManager from "../../utils/services/eventManager.service";

interface IAppEvents {
  PANEL_UPDATE: string;
  PANEL_EXPAND: string;
  PANEL_RETRACT: string;
  PANEL_EXPLODE: string;
  HEAD_SHOW: string;
  HEAD_HIDE: string;
}

export enum APP_EVENTS {
  PANEL_UPDATE = "panel_update",
  PANEL_EXPAND = "panel_expand",
  PANEL_RETRACT = "panel_retract",
  PANEL_EXPLODE = "panel_explode",
  HEAD_SHOW = "head_show",
  HEAD_HIDE = "head_hide",
}

export class EventsManager {
  static instance: EventManager = new EventManager();
  static setup() {
    if (!EventsManager.instance) {
      EventsManager.instance = new EventManager();
    }

    for (const eventName in APP_EVENTS) {
      EventsManager.instance.registerEvent(
        APP_EVENTS[eventName as keyof IAppEvents]
      );
    }
  }
  static disconnet() {
    EventsManager.instance.disconnect();
  }
}
