import { keyframes } from "styled-components";
import { isDesktop } from "utils/platform";

export const EASE_FUNC = {
  cubicSine: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
  cubicArc: "cubic-bezier(0.445, 0, 0.760, 0.380)",
  cubicInOut: "cubic-bezier(0.565, 0.005, 1.000, 1)",
  sineInOut: "cubic-bezier(0.445, 0.050, 0.550, 0.950)",
  cubicOut: "cubic-bezier(0.000, 0.000, 0.580, 1.000)",
  expoOut: "cubic-bezier(0.190, 1.000, 0.220, 1.000)",
  ease: "cubic-bezier(0.250, 0.100, 0.250, 1.000)",
  easeIn: "cubic-bezier(0.420, 0.000, 1.000, 1.000)",
  easeOut: "cubic-bezier(0.000, 0.000, 0.580, 1.000)",
  easeOutBack: "cubic-bezier(0.175, 0.885, 0, 1.330)",
  easeOutBack2: "cubic-bezier(0.34,1.36,0.79,3)",
  easeInBack: "cubic-bezier(0.155, -0.500, 0.825, 0.980)",
  easeOutQuad: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
  easeInOut: "cubic-bezier(0.420, 0.000, 0.580, 1.000)",
  easeCustom: "cubic-bezier(0.530, 0.005, 0.325, 0.995)",
  easeLinear: "cubic-bezier(0.250, 0.250, 0.750, 0.750)",
  easeEase: "cubic-bezier(0.260, 0.780, 0.675, 0.915)",
};

export const spin = () => keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(1turn); }
`;

export const fadeMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.6, delay: 0.3 },
};

export const slideUpMotionProps = {
  initial: { opacity: 0, y: "25%" },
  animate: { opacity: 1, y: "0%" },
  exit: { opacity: 0, y: "25%" },
  transition: { duration: 0.6 },
};

export const slideUpTitleMotionProps = {
  initial: { opacity: 0, y: "15%", rotate: isDesktop() ? "350deg" : "354deg" },
  animate: { opacity: 1, y: "0%", rotate: isDesktop() ? "350deg" : "354deg" },
  exit: { opacity: 0, y: "15%", rotate: isDesktop() ? "350deg" : "354deg" },
};

export const loadingMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 },
};

export const aboutMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5, delay: 0.6 },
};

export const pageMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.6 },
};
