import { motion } from "framer-motion";
import styled from "styled-components";
import { setVh } from "../../utils/styles/mixins";

export const Wrapper = styled(motion.div)`
  width: 100vw;
  height: ${setVh(100)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  overflow: auto;
  /* padding: 40rem 20rem; */

  h1 {
    color: white;
    font-size: 100rem;
  }
`;
