import styled from "styled-components";
import { colors, layout } from "../../utils/styles/theme";
import {
  setCenter,
  setCenterX,
  setRatio,
  setSize,
  setVh,
} from "../../utils/styles/mixins";
import { mediaDesktop, mediaMobile } from "../../utils/styles/responsive";
import { EASE_FUNC } from "../../utils/styles/animations";
import { motion } from "framer-motion";

export const Gate = styled.section`
  ${setCenter("absolute")};
  ${setSize("120%", "120%")};
  ${setRatio(1920, 1080)};

  .fill-color {
    fill: ${colors.cherryPie};
  }
  .fill-none {
    fill: none;
  }
  .fill-line {
    fill: none;
    stroke-width: 3px;
  }
  .fill-text {
    font: 85px Archivo;
  }

  .--section {
    position: absolute;
    ${setSize("100%", "100%")};
    top: 0;
  }

  .--left {
    left: 0;
    animation: stroke-anim 0.6s ${EASE_FUNC.easeInOut};
  }

  .--right {
    animation: stroke-anim-right 0.6s ${EASE_FUNC.easeInOut};
    right: 0;
  }

  @keyframes stroke-anim {
    0% {
      stroke-dasharray: 350;
    }
    100% {
      stroke-dasharray: 1472;
    }
  }
  @keyframes stroke-anim-right {
    0% {
      stroke-dasharray: 350;
    }
    100% {
      stroke-dasharray: 1900;
    }
  }

  .vertical-text {
    display: none;
  }

  .horizontal-text {
    display: block;
  }

  .-text--fill {
    position: absolute;
    ${setSize("100%", "100%")};
    top: 0;
    font-family: Archivo;
    text-transform: uppercase;
  }

  ${mediaMobile(`
      .vertical-text {
        display: block;
      }
      .horizontal-text {
        display: none;
      }
      ${setSize("200vw", "200vh")};
      ${setRatio(1080, 1920)};
  `)}
`;
export const TextBox = styled(motion.div)``;
export const PolygonShape = styled(motion.polygon)``;

export const GateBox = styled(motion.div)`
  position: absolute;
  width: 200vw;
  height: 75vh;
  background-color: ${colors.cherryPie};
  left: 0;
  z-index: -1;
  transform-origin: top center;

  ${mediaDesktop(`
    display: none;
  `)}
`;
export const GateLeft = styled.div`
  position: absolute;
  ${setSize("100%", "100%")};
  top: 0;
  left: 0rem;

  ${GateBox} {
    top: 0rem;
    transform-origin: bottom center;
  }
`;
export const GateRight = styled.div`
  position: absolute;
  ${setSize("100%", "100%")};
  top: 0;
  right: 0;

  ${GateBox} {
    bottom: 0rem;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.cherryPie};
  z-index: ${layout.zIndex.modals};
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  ${Gate} {
    display: none;
  }

  &.show {
    opacity: 0.9 !important;
    visibility: visible;
    transition: background-color 0.6s ${EASE_FUNC.sineInOut},
      opacity 0.3s ${EASE_FUNC.sineInOut};

    ${Gate} {
      display: block;
    }
  }

  &.open {
    background-color: transparent;
    ${Gate} {
      ${GateLeft} {
        animation: open-left 0.6s 0.2s ${EASE_FUNC.easeInOut},
          move-left 1.3s 0.8s ${EASE_FUNC.easeInOut};
      }

      ${GateRight} {
        animation: open-right 0.6s 0.2s ${EASE_FUNC.easeInOut},
          move-right 1.3s 0.8s ${EASE_FUNC.easeInOut};
      }

      .-text--fill {
        opacity: 0;
      }

      .fill-line {
        stroke-width: 0;
        transition: stroke-width 0.3s 0.1s ${EASE_FUNC.sineInOut};
      }
    }
  }

  @keyframes move-left {
    0% {
      transform: translateX(-1%);
    }
    100% {
      transform: translateX(-125%);
    }
  }
  @keyframes open-left {
    0% {
      transform: translateX(0%);
    }
    20% {
      transform: translateX(-1%);
    }
  }

  @keyframes open-right {
    0% {
      transform: translateX(0%);
    }
    20% {
      transform: translateX(1%);
    }
  }

  @keyframes move-right {
    0% {
      transform: translateX(1%);
    }
    100% {
      transform: translateX(125%);
    }
  }
`;

export const ButtonStart = styled.div`
  ${setCenterX("fixed")};
  transform: translateX(-50%) scaleY(0);
  bottom: ${layout.appSizes.margin};
  cursor: pointer;
  opacity: 0;
  transform-origin: 0% 100%;
  transition: opacity 0.6s 0.2s ${EASE_FUNC.sineInOut},
    transform 0.2s ${EASE_FUNC.sineInOut};
  pointer-events: all;
  z-index: ${layout.zIndex.ui};

  &.show {
    opacity: 1;
    transform: translateX(-50%) scaleY(1);
    transition: opacity 0.2s ${EASE_FUNC.sineInOut},
      transform 0.2s ${EASE_FUNC.sineInOut};
  }

  ${mediaMobile(`
    bottom: ${setVh(10)};
  `)}
`;

export const SoundText = styled(motion.p)`
  width: 100%;
  font-family: MontserratRegular;
  font-size: 10rem;
  line-height: 1.2;
  opacity: 1;
  text-align: center;
  color: ${colors.white};
  ${setCenterX("fixed")};
  bottom: ${setVh(5)};
  z-index: ${layout.zIndex.modals};

  ${mediaDesktop(`
    font-size: 18rem;
    margin: 44rem auto 0 auto;
    bottom: ${setVh(3)};
  `)}
`;
