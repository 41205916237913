const loadFonts = (path: string) => `
  
  @font-face {
    font-family: 'Archivo';
    src: url('${path}/fonts/ArchivoBlack-Regular.woff2') format('woff2'),
        url('${path}/fonts/ArchivoBlack-Regular.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MontserratRegular';
    src: url('${path}/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('${path}/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MontserratBlack';
    src: url('${path}/fonts/Montserrat-Black.woff2') format('woff2'),
        url('${path}/fonts/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;

export default loadFonts;
