import React, { useCallback, useEffect, useState } from 'react';

import * as Styled from './ButtonProject.styles';
import Marquee from "react-fast-marquee";
import { colors } from '../../utils/styles/theme';
import { delay } from '../../utils/time';
import useGlobalStore from '../../utils/store';
import { isDesktop } from 'utils/platform';
import { IProject } from 'utils/types';
import { PROJECT_IDS } from 'data/content';

export interface IButtonProject {
  title: string;
  techs: string[];
}

export interface ButtonProjectProps {
  onAction: () => void;
  data: IButtonProject;
  $isVisible: boolean;
}

const ButtonProject: React.FC<ButtonProjectProps> = ({
  onAction,
  data,
  $isVisible,
}) => {
  const [techs, setTechs] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [showButton, setShowButton] = useState<boolean>(false);
  const { projectID } = useGlobalStore();
  const [currentProject, setCurrentProject] = useState<IProject>(
    PROJECT_IDS.work[projectID]
  );

  useEffect(() => {
    setCurrentProject(PROJECT_IDS.work[projectID]);
  }, [projectID]);

  const getData = useCallback(async () => {
    const techs: any[] = [];
    setTechs([]);
    setTitle("");
    setShowButton(false);
    await delay(0.5);
    let count = 0;
    for (let i = 0; i < data.techs.length * 2; i++) {
      techs.push(
        <Styled.TechsItem key={i}>{data.techs[count]}</Styled.TechsItem>
      );
      count++;
      if (count > data.techs.length) count = 0;
    }
    setShowButton(true);
    await delay(0.5);
    setTechs(techs);
    setTitle(data.title);
  }, [data.techs, data.title]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  if (!data || !data.title || !data.techs) return null;

  return (
    <Styled.Wrapper
      onClick={onAction}
      className={showButton && $isVisible ? "show" : ""}
    >
      <Styled.Shape
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 376.83 208.32"
      >
        <g>
          <g>
            <polygon
              className="cls-1"
              points="0.5 0.5 376.33 0.5 376.33 88.33 329.86 134.8 329.86 207.82 0.5 207.82 0.5 0.5"
            />
            <rect
              className="cls-2"
              x="325.52"
              y="44.67"
              width="3.06"
              height="44.68"
            />
            <rect
              className="cls-3"
              x="304.59"
              y="65.35"
              width="45.19"
              height="3.06"
            />
            <polygon
              className="cls-4"
              points="0.5 0.5 376.33 0.5 376.33 88.33 329.86 132.88 329.86 207.82 0.5 207.82 0.5 0.5"
            />
          </g>
        </g>
      </Styled.Shape>

      <Styled.Projects>
        <Marquee
          gradient={true}
          gradientColor={colors.cherryPie}
          gradientWidth={showButton ? 60 : 300}
        >
          <Styled.Title>{title}</Styled.Title>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Title>{title}</Styled.Title>
        </Marquee>
      </Styled.Projects>
      <Styled.Techs>
        <Marquee
          speed={isDesktop() ? 90 : 30}
          gradient={true}
          gradientColor={colors.cherryPie}
          gradientWidth={showButton ? 60 : 300}
        >
          {techs}
        </Marquee>
      </Styled.Techs>
    </Styled.Wrapper>
  );
};

export default ButtonProject;
