export const headVertex = /* glsl */ `
    attribute float size;
    attribute vec3 customColor;

    varying vec3 vColor;

    uniform float uTime;

    void main() {

        vColor = customColor;

        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        float freq = 10.0;
        gl_PointSize = size * ( 300.0 / -mvPosition.z ) + sin(freq * mvPosition.z + uTime);

        gl_Position = projectionMatrix * mvPosition;

    }
`;
export const headFragment = /* glsl */ `
    uniform vec3 color;
    uniform float uOpacity;
    uniform sampler2D pointTexture;

    varying vec3 vColor;

    void main() {
        vec4 text = texture2D( pointTexture, gl_PointCoord );
        gl_FragColor = vec4( color * vColor, text.a * uOpacity);
        gl_FragColor = gl_FragColor * text;

    }
`;
