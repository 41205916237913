export const panelVertex = /* glsl */ `
    uniform vec2 shiftOffset;
    varying vec2 vUv;

    #define M_PI 3.1415926535897932384626433832795

    vec3 deformationCurve(vec3 position, vec2 uv, vec2 offset) {
        position.x = position.x + (sin(uv.y * M_PI) * offset.x);
        position.y = position.y + (sin(uv.x * M_PI) * offset.y);
        return position;
    }

    void main() {
        vUv = uv;
        vec3 newPosition = deformationCurve(position, uv, shiftOffset);
        gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );
    }
`;

export const panelFragment = /* glsl */ `
    uniform sampler2D t1;
    uniform sampler2D t2;
    uniform float transition;
    uniform vec2 shiftOffset;
    varying vec2 vUv;

    vec3 pixelShift(sampler2D textureImage, vec2 uv, vec2 offset) {
        float r = texture2D(textureImage,uv + offset).r;
        float g = texture2D(textureImage,uv + offset).g;
        float b = texture2D(textureImage,uv + offset).b;
        return vec3(r,g, b);
    }

    vec3 rgbShift(sampler2D textureImage, vec2 uv, vec2 offset) {
        float r = texture2D(textureImage,uv + offset).r;
        vec2 gb = texture2D(textureImage,uv).gb;
        return vec3(r,gb);
    }

    void main(){
        vec4 tex1 = texture2D(t1, vUv);
        vec4 tex2 = texture2D(t2, vUv);

        vec3 color1 = rgbShift(t1,vUv,shiftOffset) * pixelShift(t1,vUv,shiftOffset);
        vec3 color2 = rgbShift(t2,vUv,shiftOffset);
        tex1.rgb *= color1.rgb;
        tex2.rgb *= color2.rgb;
        gl_FragColor = mix(tex1, tex2, transition);
;
        float depth = gl_FragCoord.z / gl_FragCoord.w;
        const vec3 fogColor = vec3( 0.0 );

        float fogFactor = smoothstep( 2.0, 50.0, depth );
        gl_FragColor = mix( gl_FragColor, vec4( fogColor, gl_FragColor.w ), fogFactor );

    }
`;
