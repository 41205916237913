import React from 'react';

import * as Styled from './WindowSmall.styles';

export interface WindowSmallProps {}

const WindowSmall: React.FC<WindowSmallProps> = () => {
  return (
    <Styled.Wrapper>
      <p>
        <h3>RESIZE BROWSER</h3>
        <br />
        Your browser is too small, the resolution needs to be larger to enter
        this experience.
        <br />
        Please resize to continue.
      </p>
    </Styled.Wrapper>
  );
};

export default WindowSmall;
