import {
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  ShaderMaterial,
  SRGBColorSpace,
  Vector2,
  VideoTexture,
} from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { PANEL_CONFIG, VIDEO_IDS } from "../../../data/panel";
import { panelFragment, panelVertex } from "../shaders/panel";
import gsap from "gsap";
import { getRandomInRange } from "../../../utils/math";
import { ScenesManager } from "../ScenesManager";

export class Panel extends Object3D {
  videoCurrentDOM!: HTMLVideoElement;
  videoCurrentTexture!: VideoTexture;
  videoNextDOM!: HTMLVideoElement;
  videoNextTexture!: VideoTexture;
  panel: GLTF | undefined;
  overlay: Mesh | undefined;
  overlayOpacity = 0.4;
  uniforms:
    | {
        t1: { value: VideoTexture | null };
        t2: { value: VideoTexture | null };
        transition: { value: number };
        shiftOffset: { value: Vector2 };
      }
    | undefined;
  meshes: Mesh[] = [];
  materials: ShaderMaterial[] = [];
  material: ShaderMaterial | undefined;
  xgrid = 6;
  ygrid = 3;
  counter = 1;
  cube_count = 0;
  animation = {
    counter: 0,
  };
  expanded = false;
  animating = false;
  configIndex = 0;
  videoIds = [
    VIDEO_IDS.beefeater,
    VIDEO_IDS.ccxp,
    VIDEO_IDS.dreamworld,
    VIDEO_IDS.fiatstrada,
    VIDEO_IDS.globo,
    VIDEO_IDS.lcdp,
    VIDEO_IDS.tiraonda,
    VIDEO_IDS.wristbands,
  ];
  shiftOffsetTween!: gsap.core.Tween;
  transitionTween!: gsap.core.Tween;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(state: "retracted" | "expanded" | "exploded") {
    super();

    this.build(state);
  }

  build(state: "retracted" | "expanded" | "exploded") {
    this.videoCurrentDOM = document.getElementById(
      VIDEO_IDS.dreamworld
    ) as HTMLVideoElement;

    this.videoCurrentDOM.currentTime = 0;
    this.videoCurrentDOM.play();
    this.videoCurrentDOM.addEventListener("suspend", () => {
      window.addEventListener("pointerdown", () => {
        this.videoCurrentDOM.play();
      });
    });
    this.videoCurrentTexture?.dispose();
    this.videoCurrentTexture = new VideoTexture(this.videoCurrentDOM);
    this.videoCurrentTexture.colorSpace = SRGBColorSpace;

    let i, j, ox, oy, geometry;

    const ux = 1 / this.xgrid;
    const uy = 1 / this.ygrid;

    const xsize = 16 / this.xgrid;
    const ysize = 9 / this.ygrid;

    this.material = this.getMaterial();

    this.cube_count = 0;

    for (i = 0; i < this.xgrid; i++) {
      for (j = 0; j < this.ygrid; j++) {
        ox = i;
        oy = j;

        geometry = new BoxGeometry(xsize, ysize, 0.15);

        this.changeUvs(geometry, ux, uy, ox, oy);

        this.materials[this.cube_count] = this.material;

        const material = this.materials[this.cube_count];
        const mesh = new Mesh(geometry, material);
        const position = {
          x: (i - this.xgrid / 2) * xsize,
          y: (j - this.ygrid / 2) * ysize,
          z: 0,
        };
        mesh.position.x = position.x;
        mesh.position.y = position.y;
        mesh.position.z = position.z;
        mesh.userData.gridPosition = position;
        this.add(mesh);

        mesh.userData.dx = 0.01 * (0.5 - Math.random());
        mesh.userData.dy = 0.01 * (0.5 - Math.random());

        this.meshes[this.cube_count] = mesh;

        this.cube_count += 1;
      }
    }
    this.overlay = new Mesh(
      new PlaneGeometry(16, 9),
      new MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: this.overlayOpacity,
      })
    );
    this.overlay.position.set(0, 0, 2.3);
    this.add(this.overlay);

    if (state === "exploded") {
      this.explode(true);
    } else if (state === "expanded") {
      this.explode(true);
      this.expand(true);
    }
  }

  getMaterial() {
    this.uniforms = {
      t1: { value: this.videoCurrentTexture },
      t2: { value: null },
      transition: { value: 0 },
      shiftOffset: { value: new Vector2(0.0, 0.0) },
    };
    return new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: panelVertex,
      fragmentShader: panelFragment,
    });
  }

  changeUvs(
    geometry: BoxGeometry,
    unitx: number,
    unity: number,
    offsetx: number,
    offsety: number
  ) {
    const uvs = geometry.attributes.uv.array;

    for (let i = 0; i < uvs.length; i += 2) {
      uvs[i] = (uvs[i] + offsetx) * unitx;
      uvs[i + 1] = (uvs[i + 1] + offsety) * unity;
    }
  }

  updateVideo(video: string) {
    // this.videoCurrentDOM?.pause();
    this.videoNextDOM = document.getElementById(video) as HTMLVideoElement;
    this.videoCurrentDOM = this.videoNextDOM;
    if (this.videoNextDOM) {
      this.videoIds.forEach((vidId) => {
        if (vidId !== video) {
          const otherVideo = document.getElementById(video) as HTMLVideoElement;
          otherVideo?.pause();
        }
      });
      this.videoNextDOM?.play();
      this.videoCurrentDOM?.play();
      // this.videoNextDOM.currentTime = 0;
      this.videoNextDOM.addEventListener("suspend", () => {
        window.addEventListener("pointerdown", () => {
          this.videoNextDOM.play();
        });
      });
    }
    this.videoNextTexture?.dispose();
    this.videoNextTexture = new VideoTexture(this.videoNextDOM);
    this.videoNextTexture.colorSpace = SRGBColorSpace;
    if (this.uniforms) {
      this.uniforms.t1.value = this.videoCurrentTexture;
      this.uniforms.t2.value = this.videoNextTexture;
      const transition = {
        value: 0.0,
      };
      const shift = {
        x: getRandomInRange(-0.015, 0.015),
        y: getRandomInRange(-0.015, 0.015),
      };
      this.shiftOffsetTween?.kill();
      this.shiftOffsetTween = gsap.fromTo(
        this.uniforms.shiftOffset.value,
        { x: 0.0, y: 0.0 },
        {
          x: shift.x,
          y: shift.y,
          duration: 0.2,
          ease: "power3.inOut",
          onComplete: () => {
            if (this.uniforms) {
              this.shiftOffsetTween?.kill();
              this.shiftOffsetTween = gsap.fromTo(
                this.uniforms.shiftOffset.value,
                { x: -shift.x, y: -shift.y },
                {
                  x: 0.0,
                  y: 0.0,
                  delay: 0.1,
                  ease: "power2.inOut",
                  duration: 0.3,
                }
              );
            }
          },
        }
      );
      this.transitionTween?.kill();
      this.transitionTween = gsap.fromTo(
        transition,
        { value: 0 },
        {
          value: 1,
          duration: 0.8,
          ease: "power3.inOut",
          onUpdate: () => {
            if (this.uniforms) {
              this.uniforms.transition.value = transition.value;
            }
          },
          onComplete: () => {
            this.videoCurrentTexture?.dispose();
            this.videoCurrentTexture = this.videoNextTexture;
          },
        }
      );
    }
  }

  expand(fromHome: boolean) {
    this.animating = true;

    const duration = 1.2;
    const config = PANEL_CONFIG[this.configIndex];
    this.configIndex++;
    if (this.configIndex > PANEL_CONFIG.length - 1) this.configIndex = 0;
    const PI_4 = Math.PI / 4;
    for (let i = 0; i < this.cube_count; i++) {
      const mesh = this.meshes[i];
      let x = Math.sin(i) * 10;
      let y = Math.cos(i) * 10;
      let z = i % 2 === 0 ? i + 1 : -(i + 1);

      if (config[i]) {
        x = config[i].x;
        y = config[i].y;
        z = config[i].z;
      } else {
        x = Math.sin(i) * 15;
        y = Math.cos(i) * 15;
        z = getRandomInRange(-15, -40);
      }

      gsap.to(mesh.position, {
        x: x,
        y: y,
        z: z,
        duration: duration,
        delay: i / 100,
        ease: fromHome ? "elastic.out(1, 0.75)" : "elastic.out(0.5, 1)",
      });

      gsap.to(mesh.rotation, {
        x: getRandomInRange(-PI_4, PI_4),
        y: getRandomInRange(-PI_4, PI_4),
        z: getRandomInRange(-PI_4, PI_4),
        duration: duration,
        delay: 0.1,
      });
      const scale = getRandomInRange(0.8, 1.4);
      gsap.to(mesh.scale, {
        x: scale,
        y: scale,
        z: scale,
        duration: duration,
        delay: 0.1,
      });
    }
    if (this.overlay) {
      gsap.to(this.overlay.material, {
        opacity: 0,
        duration: duration,
        delay: 1,
        ease: "power4.inOut",
      });
    }

    gsap.delayedCall(1, () => {
      this.animating = false;
      this.expanded = true;
      ScenesManager.followMouseEnabled = true;
    });
  }

  retract() {
    this.animating = true;
    this.expanded = false;
    this.videoCurrentDOM?.play();
    ScenesManager.followMouseEnabled = false;
    const duration = 1;
    for (let i = 0; i < this.cube_count; i++) {
      const mesh = this.meshes[i];
      gsap.to(mesh.position, {
        x: mesh.userData.gridPosition.x,
        y: mesh.userData.gridPosition.y,
        z: mesh.userData.gridPosition.z,
        duration: duration,
        ease: "power2.inOut",
      });
      gsap.to(mesh.rotation, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.6,
        delay: duration * 0.5,
      });
      gsap.to(mesh.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: duration * 0.8,
      });
    }
    if (this.overlay) {
      gsap.to(this.overlay.material, {
        opacity: this.overlayOpacity,
        duration: duration,
        delay: 0.4,
        ease: "power4.inOut",
      });
    }
    gsap.delayedCall(1.2, () => {
      this.animating = false;
    });
  }

  explode(initial: boolean) {
    this.animating = true;
    if (!initial) {
      if (this.videoCurrentDOM) this.videoCurrentDOM.pause();
      if (this.videoNextDOM) this.videoNextDOM.pause();
    }
    const PI_4 = Math.PI / 4;
    for (let i = 0; i < this.cube_count; i++) {
      const mesh = this.meshes[i];
      const x = Math.sin(i) * 30;
      const y = Math.cos(i) * 30;
      const z = 30;
      gsap.to(mesh.position, {
        x: x,
        y: y,
        z: z,
        delay: i / 100,
        duration: initial ? 0 : 2,
        ease: "power4.inOut",
      });

      gsap.to(mesh.rotation, {
        x: getRandomInRange(-PI_4, PI_4),
        y: getRandomInRange(-PI_4, PI_4),
        z: getRandomInRange(-PI_4, PI_4),
        duration: initial ? 0 : 2,
        delay: 0.2,
      });
    }
    if (this.overlay) {
      gsap.to(this.overlay.material, {
        opacity: 0,
        duration: initial ? 0 : this.overlayOpacity,
        delay: 1,
        ease: "power4.inOut",
      });
    }
    gsap.delayedCall(1, () => {
      this.animating = false;
    });
  }
}
