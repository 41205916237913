export const delay = (sec: number) => {
  try {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve(true);
      }, sec * 1000)
    );
  } catch (error) {
    console.error(error);
  }
};
