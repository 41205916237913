import { createGlobalStyle, css } from "styled-components";

import resetCSS from "./reset";
import { setScalableFontSize } from "./responsive";
import { colors, layout } from "./theme";

const style = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    user-select: text;
  }

  ::selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  ::-moz-selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    background-color: ${colors.cherryPie};
  }

  html {
    ${setScalableFontSize()}
    position: absolute;
    overflow: hidden;
  }

  #root {
    width: 100vw;
    height: 100vh;
  }

  body {
    background: ${colors.black};
    color: ${colors.white};
    transition: transform 0.3s ease; /* Smooth transition */

    i {
      font-style: italic;
    }
  }

  button,
  input,
  textarea,
  select,
  dialog {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    font-family: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    outline: none;
    border-radius: 0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  svg {
    outline: none;
    display: block;
  }

  button,
  div,
  a,
  span {
    -webkit-tap-highlight-color: transparent;
  }

  a {
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .tp-dfwv {
    z-index: ${layout.zIndex.modals};
  }
`;

export default createGlobalStyle`
  ${resetCSS}
  ${style}
`;
