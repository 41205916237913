import styled from "styled-components";
import { layout } from "./utils/styles/theme";
import { motion } from "framer-motion";
import { setVh } from "./utils/styles/mixins";
import { mediaMobile } from "./utils/styles/responsive";

export const Wrapper = styled.div`
  width: 100vw;
  height: ${setVh(100)};
`;

export const ButtonNavWrapper = styled(motion.div)`
  width: calc(100% - 9.37vw - ${layout.appSizes.menuDeskptopHeight});
  height: 90rem;
  position: absolute;
  bottom: ${setVh(8)};
  left: ${layout.appSizes.margin};
  overflow: hidden;
  cursor: pointer;
  z-index: ${layout.zIndex.ui};
  ${mediaMobile(`
    width: calc(100% - 9.37vw);
    bottom: ${setVh(10)};
    height: 60rem;
  `)};
`;

export const ButtonBackWrapper = styled(motion.div)`
  position: absolute;
  left: ${layout.appSizes.margin};
  top: ${layout.appSizes.margin};
  overflow: hidden;
  cursor: pointer;
`;

export const Canvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 0.6s;
`;

export const Video = styled.video`
  position: absolute;
  width: 0;
  height: 0;
  top: -1;
  left: -1;
`;
