/* eslint-disable react/style-prop-object */
import React, { useCallback, useEffect, useState } from 'react';

import * as Styled from './LoadingScreen.styles';
import ButtonCta from '../ButtonCta/ButtonCta';
import useGlobalStore from '../../utils/store';
import { SoundController } from 'webgl/controllers/SoundController';
import { SOUND_LABELS } from '../../data/sound';
import { delay } from 'utils/time';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'routes';
import { fadeMotionProps } from 'utils/styles/animations';
import { colors } from 'utils/styles/theme';
import { isDesktop } from 'utils/platform';

export interface LoadingScreenProps {
  isVisible: boolean;
  showCTA: boolean;
  onCompleteLoad: () => void;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  isVisible,
  showCTA,
  onCompleteLoad,
}) => {
  const location = useLocation();
  const { isLoading, showUI } = useGlobalStore();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const goToHome = useCallback(async () => {
    SoundController.playAudio(SOUND_LABELS.GATE);
    setIsOpen(true);
    await delay(0.5);
    setTimeout(() => {
      SoundController.playAudio(
        location.pathname === ROUTES.ABOUT
          ? SOUND_LABELS.ABOUT
          : SOUND_LABELS.MAIN
      );
      onCompleteLoad();
    }, 1000);
  }, [location.pathname, onCompleteLoad]);

  const onKeyDown = useCallback(
    (e: { key: any }) => {
      if (showUI) return;
      switch (e.key) {
        case "Enter":
          goToHome();
          break;
      }
    },
    [goToHome, showUI]
  );

  useEffect(() => {
    if (!isLoading) {
      setShow(true);
  
      if (isDesktop()) {
        document.addEventListener("keydown", onKeyDown);
      } 
    } 
    return () => {
      setShow(false);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [isLoading, onKeyDown]);

  return (
    <Styled.Wrapper
      style={{ background: showCTA ? "transparent" : "#220240" }}
      className={
        isVisible && show && !isOpen
          ? "show"
          : isVisible && show && isOpen
          ? "show open"
          : ""
      }
    >
      <Styled.Gate>
        {/* <!-- GATES BG --> */}
        <Styled.GateLeft>
          <Styled.GateBox
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ delay: 0.3, duration: 1 }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="--section --left"
            viewBox="0 0 1920 1080"
          >
            <g>
              <g>
                <polygon
                  className="fill-none"
                  points="1920 1080 1920 0 1484.85 0 1175.05 309.79 1300.26 434.47 1299.96 769.84 572.49 769.84 262.55 1080 1920 1080"
                />
                <Styled.PolygonShape
                  className="fill-color"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: 0.1, duration: 0.3 }}
                  points="0 0 0 1080 262.55 1080 572.49 769.84 572.49 661.24 620.02 613.81 620.02 309.92 1175.05 309.79 1484.85 0 0 0"
                />
              </g>
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="--section --left"
            viewBox="0 0 1920 1082.12"
          >
            <g>
              <g>
                <polygon
                  className="fill-none"
                  points="1920 1081.06 1920 1.06 1484.85 1.06 1175.05 310.85 1300.26 435.53 1299.96 770.9 572.49 770.9 262.55 1081.06 1920 1081.06"
                />
                <polygon
                  className="fill-none"
                  points="0 1.06 0 1081.06 262.55 1081.06 572.49 770.9 572.49 662.3 620.02 614.88 620.02 310.98 1175.05 310.85 1484.85 1.06 0 1.06"
                />
                <polyline
                  className="fill-line"
                  stroke={colors.brightTurquoise}
                  points="262.55 1081.06 572.49 770.9 572.49 662.3 620.02 614.88 620.02 310.98 1174.22 311.03 1484.85 1.06"
                />
              </g>
            </g>
          </svg>
        </Styled.GateLeft>
        <Styled.GateRight>
          <Styled.GateBox
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ delay: 0.3, duration: 1 }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="--section --right"
            viewBox="0 0 1920 1080"
          >
            <g>
              <g>
                <Styled.PolygonShape
                  className="fill-color"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: 0.1, duration: 0.3 }}
                  points="1920 1080 1920 0 1484.85 0 1175.05 309.79 1300.26 434.47 1299.96 769.84 572.49 769.84 262.55 1080 1920 1080"
                />
                <polygon
                  className="fill-none"
                  points="0 0 0 1080 262.55 1080 572.49 769.84 572.49 661.24 620.02 613.81 620.02 309.92 1175.05 309.79 1484.85 0 0 0"
                />
              </g>
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="--section --right"
            viewBox="0 0 1920 1080"
          >
            <g>
              <g>
                <polygon
                  className="fill-none"
                  points="1920 1081.06 1920 1.06 1484.85 1.06 1175.05 310.85 1300.26 435.53 1299.96 770.9 572.49 770.9 262.55 1081.06 1920 1081.06"
                />
                <polygon
                  className="fill-none"
                  points="0 1.06 0 1081.06 262.55 1081.06 572.49 770.9 572.49 662.3 620.02 614.88 620.02 310.98 1175.05 310.85 1484.85 1.06 0 1.06"
                />
                <polyline
                  className="fill-line"
                  stroke={colors.brightTurquoise}
                  points="1484.85 1.06 1174.22 311.03 1300.26 435.53 1299.96 770.9 571.96 771.12 262.55 1081.06"
                />
              </g>
            </g>
          </svg>
        </Styled.GateRight>

        {isVisible && show && !isOpen && (
          <Styled.TextBox
            {...fadeMotionProps}
            transition={{ delay: 0.5, duration: 1.2 }}
          >
            {/* <!-- TEXT OUTLINE --> */}
            <svg
              className="-text--fill"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1920 1080"
            >
              <text
                x="0"
                y="50%"
                dy="42px"
                className="fill-text"
                stroke="none"
                // stroke={colors.brightTurquoise}
                fill={colors.brightTurquoise}
              >
                <animate
                  attributeType="transform"
                  attributeName="dx"
                  from="0%"
                  to="-100%"
                  dur="6s"
                  repeatCount="indefinite"
                />
                CAIO&nbsp;BASSETTI&ensp;&ensp;&ensp;&ensp;CREATIVE&nbsp;CODER
              </text>
              <text
                x="0"
                y="50%"
                dy="42px"
                className="fill-text"
                stroke="none"
                // stroke={colors.brightTurquoise}
                fill={colors.brightTurquoise}
              >
                <animate
                  attributeType="transform"
                  attributeName="dx"
                  from="100%"
                  to="0%"
                  dur="6s"
                  repeatCount="indefinite"
                />
                CAIO&nbsp;BASSETTI&ensp;&ensp;&ensp;&ensp;CREATIVE&nbsp;CODER
              </text>
            </svg>
            {/* <!-- TEXT OUTLINE --> */}
            {/* <!-- TEXT FILL --> */}

            <svg
              className="-text--fill"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1920 1080"
            >
              <mask id="textFillMask">
                <path
                  className="cls-1"
                  d="M1302.37,435.28,1177.17,310.6l-555,.12L2.12.81h1920v1080H2.12V.81l620,309.91v303.9l-47.53,47.43-.53,108.81,728-.21Z"
                  fill="white"
                />
              </mask>
              <text
                x="0"
                y="50%"
                dy="42px"
                className="fill-text"
                mask="url(#textFillMask)"
                stroke={colors.brightTurquoise}
                fill={colors.cherryPie}
              >
                <animate
                  attributeType="transform"
                  attributeName="dx"
                  from="0%"
                  to="-100%"
                  dur="6s"
                  repeatCount="indefinite"
                />
                CAIO&nbsp;BASSETTI&ensp;&ensp;&ensp;&ensp;CREATIVE&nbsp;CODER
              </text>
              <text
                x="0"
                y="50%"
                dy="42px"
                className="fill-text"
                mask="url(#textFillMask)"
                stroke={colors.brightTurquoise}
                fill={colors.cherryPie}
              >
                <animate
                  attributeType="transform"
                  attributeName="dx"
                  from="100%"
                  to="0%"
                  dur="6s"
                  repeatCount="indefinite"
                />
                CAIO&nbsp;BASSETTI&ensp;&ensp;&ensp;&ensp;CREATIVE&nbsp;CODER
              </text>
            </svg>
          </Styled.TextBox>
        )}
      </Styled.Gate>
      <Styled.ButtonStart
        className={!isOpen && !isLoading && showCTA ? "show" : ""}
      >
        <ButtonCta
          label="OPEN THE GATE"
          onDisabled={!showCTA}
          onAction={goToHome}
        />
      </Styled.ButtonStart>
      {isVisible && !isOpen && showCTA && (
        <Styled.SoundText
          {...fadeMotionProps}
          transition={{
            duration: 0.6,
            delay: 0,
          }}
        >
          For a better experience
          <br />
          use headphones
        </Styled.SoundText>
      )}
    </Styled.Wrapper>
  );
};

export default LoadingScreen;
