import styled from "styled-components";
import {
  setCenterX,
  setCenterY,
  setSize,
  setVh,
} from "../../utils/styles/mixins";
import { colors, layout } from "../../utils/styles/theme";
import { mediaMobile } from "../../utils/styles/responsive";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
  ${setSize("auto", "2.29vw")}
  min-height: 32px;
  min-width: 980rem;
  ${setCenterX("fixed")};
  bottom: ${layout.appSizes.margin};
  padding: 0 30rem;
  background: ${colors.cherryPie};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${layout.zIndex.ui + 1};
  transform-origin: center bottom;
  cursor: pointer;
  ${mediaMobile(`
    min-width: 0;
    width: 91vw;
    height: ${setVh(4.5)};
    bottom: ${setVh(10)};
    background: ${colors.cherryPie};
`)};
`;
export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const Item = styled(motion.li)`
  ${setSize("20%", "30rem")};
  max-width: 180px;
  margin: 0 0rem;
  cursor: pointer;
  transition: width 0.6s linear, height 0.6s linear;
  position: relative;

  &:after {
    content: "";
    ${setSize("100%", "2rem")}
    ${setCenterY("absolute")};
    background-color: ${colors.electricViolet};
    filter: drop-shadow(0 0 0rem white);
    transition: background-color 0.2s linear, filter 0.3s ease-in;
    cursor: pointer;
    ${mediaMobile(`
      ${setSize("100%", "1rem")}
    `)}
  }

  &.active {
    width: 40%;
    &:after {
      ${setSize("100%", "6rem")}
      background-color: ${colors.brightTurquoise};
      filter: drop-shadow(0 0 10rem white);
      transition: background-color 0.4s linear, filter 0.8s ease-in;

      ${mediaMobile(`
        ${setSize("100%", "4rem")}
      `)}
    }
  }
`;

export const ArrowRight = styled(motion.div)`
  z-index: ${layout.zIndex.ui};
  border: solid ${colors.brightTurquoise};
  border-width: 1px 1px 0 0;
  display: inline-block;
  padding: 6rem;
  transform: rotate(45deg);
  animation: arrowMoveR 2s infinite linear;
  opacity: 0;
  margin-left: 15rem;
  cursor: pointer;

  @keyframes arrowMoveR {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  ${mediaMobile(`
    display: none;
  `)}
`;
export const ArrowLeft = styled(motion.div)`
  z-index: ${layout.zIndex.ui};
  border: solid ${colors.brightTurquoise};
  border-width: 0 0 1px 1px;
  display: inline-block;
  padding: 6rem;
  transform: rotate(45deg);
  animation: arrowMoveL 2s infinite linear;
  opacity: 0;
  margin-right: 15rem;
  cursor: pointer;

  ${mediaMobile(`
    display: none;
  `)}

  @keyframes arrowMoveL {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
