import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import * as Styled from './App.styles';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from './routes';
import HomePage from './pages/HomePage/HomePage';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import AboutPage from './pages/AboutPage/AboutPage';
import Menu from './components/Menu/Menu';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

import { AnimatePresence } from 'framer-motion';
import ButtonSound from './components/ButtonSound/ButtonSound';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ButtonNavigationArrow from './components/ButtonNavigationArrow/ButtonNavigationArrow';
import ButtonBack from './components/ButtonBack/ButtonBack';
import { fadeMotionProps } from './utils/styles/animations';
import { PROJECT_IDS } from './data/content';
import useGlobalStore from './utils/store';
import NavBar from './components/NavBar/NavBar';
import { ScenesManager } from './webgl/scenes/ScenesManager';
import { VIDEO_IDS } from './data/panel';
import { APP_EVENTS, EventsManager } from './webgl/events/EventsManager';
import { SoundController } from 'webgl/controllers/SoundController';
import { SOUND_LABELS } from 'data/sound';
import { delay } from 'utils/time';
import { isBrowser, isDesktop } from 'utils/platform';
import ButtonProject from 'components/ButtonProject/ButtonProject';
import { IProject } from 'utils/types';
import usePageVisibility from 'utils/hooks/usePageVisibility';
import useWindowSize from "utils/hooks/useWindowSize";
import { desktopMinWidth, desktopMinHeight } from 'utils/styles/vars';
import WindowSmall from 'components/WindowSmall/WindowSmall';

declare global {
  interface Window {
    panel: any;
  }
}

function App() {
  const location = useLocation();
  const { projectID, setProjectID, isLoading, setIsLoading, setShowNavBar, showNavBar, setShowUI, showUI } =
    useGlobalStore();
  const navigate = useNavigate();
  const windowSize = useWindowSize(true);
  const windowToSmall = useMemo(
    () => {
      return isDesktop() &&
        (windowSize.width < desktopMinWidth ||
          windowSize.height < desktopMinHeight);
    },
    [windowSize.height, windowSize.width]
  );
  const isVisible = usePageVisibility();
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [showNavButton, setShowNavButton] = useState<boolean>(false);
  const [sceneReady, setSceneReady] = useState<boolean>(false);
  const currentProject = useRef<IProject>(
    PROJECT_IDS.work[projectID]
  );
  const projectIdRef = useRef<number>(projectID);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const getPath = () => {
    return isBrowser() ? window.location.origin : "";
  };

  const onNavProject = useCallback((direction: number) => {

    ScenesManager.inTransition = true;
    let nextIndex = direction > 0 ? projectIdRef.current + 1 : projectIdRef.current - 1;
    if (nextIndex > PROJECT_IDS.work.length - 1) nextIndex = 0;
    if (nextIndex < 0) nextIndex = PROJECT_IDS.work.length - 1;


    SoundController.playAudio(SOUND_LABELS.ABOUT_ACTION);
    SoundController.playAudio(SOUND_LABELS.ACTION);
    
    setProjectID(nextIndex);
    navigate(`${ROUTES.PROJECT}/${PROJECT_IDS.work[nextIndex].path}`);
    EventsManager.instance.trigger(APP_EVENTS.PANEL_EXPAND, {
      fromHome: true,
    });
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToProject = useCallback(async () => {
    ScenesManager.inTransition = true;
    SoundController.playAudio(SOUND_LABELS.ABOUT_ACTION);
    SoundController.playAudio(SOUND_LABELS.EXPLODE);
    setShowNavBar(false, 0);
    setTimeout(() => {
      EventsManager.instance.trigger(APP_EVENTS.PANEL_EXPAND, {
        fromHome: true,
      });
      navigate(`${ROUTES.PROJECT}/${currentProject.current.path}`);
    }, 600);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const goToBack = useCallback(async () => {
    ScenesManager.inTransition = true;
    SoundController.playAudio(SOUND_LABELS.ABOUT_ACTION);
    EventsManager.instance.trigger(APP_EVENTS.PANEL_RETRACT);
    if (location.pathname === ROUTES.ABOUT) {
      EventsManager.instance.trigger(APP_EVENTS.HEAD_HIDE);
      await delay(0.5);
      SoundController.fadeFromTo(SOUND_LABELS.ABOUT, SOUND_LABELS.MAIN);
    }
    navigate(`${ROUTES.HOME}`);
  }, [location.pathname, navigate]);

  const onKeyDown = useCallback(
    (e: { key: any }) => {
      switch (e.key) {
        // case "Enter":
        //   if (window.location.pathname === ROUTES.HOME) {
        //     goToProject();
        //   }
        //   break;
        case "ArrowLeft":
          if (window.location.pathname !== ROUTES.HOME && window.location.pathname !== ROUTES.ABOUT) {
            onNavProject(-1);
          }
          break;
        case "ArrowRight":
          if (window.location.pathname !== ROUTES.HOME && window.location.pathname !== ROUTES.ABOUT) {
            onNavProject(1);
          };
          break;
        // case "Backspace":
        //   if (
        //     window.location.pathname !== ROUTES.HOME &&
        //     window.location.pathname !== ROUTES.ABOUT
        //   ) {
        //     goToBack();
        //   }
        //   break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(()=>{
    if (showUI) {
      currentProject.current = PROJECT_IDS.work[projectID];
      if (
        location.pathname.includes(ROUTES.PROJECT) ||
        location.pathname === ROUTES.ABOUT
      ) {
        setTimeout(() => {
          setShowBackButton(true);
          setShowNavBar(false, 0);
        }, 1000);
      } else {
        setShowBackButton(false);
        setShowNavBar(true, isDesktop() ? 1.4 : 1);
      }
      if (location.pathname.includes(ROUTES.PROJECT)) {
        setTimeout(() => {
          setShowNavButton(true);
        }, 1000);
      } else {
        setShowNavButton(false);
      }      
    }
  }, [location.pathname, setShowNavBar, isLoading, showUI, projectID])

  useEffect(() => {
    if (showUI && !isLoading) {
      setTimeout(() => {

        if (isDesktop()) {
          document.addEventListener("keydown", onKeyDown);
        }
      }, 1500);
    }
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUI, isLoading]);
  
  useEffect(()=>{
    EventsManager.instance.trigger(APP_EVENTS.PANEL_UPDATE, {
      video: PROJECT_IDS.work[projectID].video,
    });

    projectIdRef.current = projectID;
  }, [projectID])
  
  useEffect(() => {
    setIsMuted(!isVisible);
    SoundController.mute(!isVisible);
  }, [isVisible]);

  return (
    <Styled.Wrapper>
      <Styled.Canvas
        style={{ opacity: !isLoading ? 1 : 0 }}
        ref={async (ref) => {
          if (canvasRef.current) return;
          canvasRef.current = ref;
          if (canvasRef.current) {
            // await delay(3)
            ScenesManager.setup(canvasRef.current);
            await delay(0.5);
            await SoundController.setupSounds();
            setIsLoading(false);
            await delay(3);
            setSceneReady(true);
          }
        }}
      />
      {showUI && !isLoading && (
        <>
          <Menu key="menu" />
          <ButtonSound
            key="button_sound"
            onAction={() => {
              setIsMuted(!isMuted);
              SoundController.mute(!isMuted);
            }}
            $isPlaying={!isMuted}
            $isVisible={showUI && !isLoading}
          />
          {!showBackButton && (
            <>
              <NavBar />
              <ButtonProject
                $isVisible={showNavBar.visible}
                onAction={goToProject}
                data={{
                  title: currentProject.current.title,
                  techs: currentProject.current.techs.split(" "),
                }}
              />
            </>
          )}
          <Styled.ButtonBackWrapper {...fadeMotionProps}>
            <ButtonBack $isVisible={showBackButton} onAction={goToBack} />
          </Styled.ButtonBackWrapper>
          <Styled.ButtonNavWrapper
            {...fadeMotionProps}
            style={{
              pointerEvents: location.pathname.includes("project")
                ? "all"
                : "none",
            }}
          >
            <ButtonNavigationArrow
              onPrevAction={() => onNavProject(-1)}
              onNextAction={() => onNavProject(1)}
              $isVisible={showNavButton}
            />
          </Styled.ButtonNavWrapper>
        </>
      )}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index path={ROUTES.HOME} element={<HomePage />} />
          <Route path={ROUTES.ABOUT} element={<AboutPage />} />
          <Route
            path={`${ROUTES.PROJECT}/:pathName`}
            element={<ProjectPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AnimatePresence>

      <LoadingScreen
        isVisible={!showUI}
        onCompleteLoad={() => {
          navigate(`${location.pathname}${location.search}`, {
            state: null,
          });
          if (location.pathname.includes(ROUTES.PROJECT)) {
            EventsManager.instance.trigger(APP_EVENTS.PANEL_UPDATE, {
              video: PROJECT_IDS.work[projectID].video,
            });
          }
          if (location.pathname === ROUTES.ABOUT) {
            EventsManager.instance.trigger(APP_EVENTS.HEAD_SHOW);
          }
          setShowUI(true);
        }}
        showCTA={sceneReady}
      />

      {windowToSmall && <WindowSmall />}
      <Styled.Video
        id={VIDEO_IDS.dreamworld}
        loop
        crossOrigin="anonymous"
        autoPlay={true}
        playsInline
        muted
        preload="auto"
      >
        <source
          src={`${getPath()}/assets/works/dreamworld/${VIDEO_IDS.dreamworld}${
            isDesktop() ? "" : "-mob"
          }.mp4`}
          type="video/mp4"
        />
      </Styled.Video>
      {!isLoading &&
        sceneReady &&(
          <>
            <Styled.Video
              id={VIDEO_IDS.beefeater}
              loop
              crossOrigin="anonymous"
              playsInline
              muted
              preload="auto"
            >
              <source
                src={`${getPath()}/assets/works/beefeater/${
                  VIDEO_IDS.beefeater
                }${isDesktop() ? "" : "-mob"}.mp4`}
                type="video/mp4"
              />
            </Styled.Video>
            <Styled.Video
              id={VIDEO_IDS.fiatstrada}
              loop
              crossOrigin="anonymous"
              playsInline
              muted
              preload="auto"
            >
              <source
                src={`${getPath()}/assets/works/fiatstrada/${
                  VIDEO_IDS.fiatstrada
                }${isDesktop() ? "" : "-mob"}.mp4`}
                type="video/mp4"
              />
            </Styled.Video>

            <Styled.Video
              id={VIDEO_IDS.ccxp}
              loop
              crossOrigin="anonymous"
              playsInline
              muted
              preload="auto"
            >
              <source
                src={`${getPath()}/assets/works/ccxp/${VIDEO_IDS.ccxp}${
                  isDesktop() ? "" : "-mob"
                }.mp4`}
                type="video/mp4"
              />
            </Styled.Video>
            <Styled.Video
              id={VIDEO_IDS.globo}
              loop
              crossOrigin="anonymous"
              playsInline
              muted
              preload="auto"
            >
              <source
                src={`${getPath()}/assets/works/globo/globo-intro${
                  isDesktop() ? "" : "-mob"
                }.mp4`}
                type="video/mp4"
              />
            </Styled.Video>
            <Styled.Video
              id={VIDEO_IDS.lcdp}
              loop
              crossOrigin="anonymous"
              autoPlay={true}
              playsInline
              muted
              preload="auto"
            >
              <source
                src={`${getPath()}/assets/works/lcdp/${VIDEO_IDS.lcdp}${
                  isDesktop() ? "" : "-mob"
                }.mp4`}
                type="video/mp4"
              />
            </Styled.Video>
            <Styled.Video
              id={VIDEO_IDS.wristbands}
              loop
              crossOrigin="anonymous"
              autoPlay={true}
              playsInline
              muted
              preload="auto"
            >
              <source
                src={`${getPath()}/assets/works/wristbands/${
                  VIDEO_IDS.wristbands
                }${isDesktop() ? "" : "-mob"}.mp4`}
                type="video/mp4"
              />
            </Styled.Video>
          </>
        )}
    </Styled.Wrapper>
  );
}

export default App;
