import { SOUND_LABELS } from "../../data/sound";
import { Howl, Howler } from "howler";
import { isBrowser } from "utils/platform";

const getPath = () => {
  // eslint-disable-next-line no-undef
  return isBrowser() ? window.location.origin : "";
};

export class SoundController {
  static sfxVolumeMax = 0.5;
  static sfxSpecialVolumeMax = 1;
  static volumeMax = 0.6;

  static sfxVolume = {
    value: SoundController.sfxVolumeMax,
  };

  static volume = {
    value: SoundController.volumeMax,
  };
  static muted = {
    value: false,
  };

  static audioEnabled = true;
  static currentLabel = "";
  static audio;

  static main;
  static about;
  static about_action;
  static action;
  static explode;
  static gate;
  static menu;

  static setupSounds() {
    Howler.volume(0.5);
    const path = getPath();
    return new Promise((resolve) => {
      SoundController[SOUND_LABELS.MAIN] = new Howl({
        src: [
          `${path}/assets/sounds/${SOUND_LABELS.MAIN}.ogg`,
          `${path}/assets/sounds/${SOUND_LABELS.MAIN}.mp3`,
        ],
        loop: true,
        volume: SoundController.volume.value,
      });
      SoundController[SOUND_LABELS.ABOUT] = new Howl({
        src: [
          `${path}/assets/sounds/${SOUND_LABELS.ABOUT}.ogg`,
          `${path}/assets/sounds/${SOUND_LABELS.ABOUT}.mp3`,
        ],
        loop: true,
        volume: SoundController.volume.value,
      });
      SoundController[SOUND_LABELS.ABOUT_ACTION] = new Howl({
        src: [
          `${path}/assets/sounds/${SOUND_LABELS.ABOUT_ACTION}.ogg`,
          `${path}/assets/sounds/${SOUND_LABELS.ABOUT_ACTION}.mp3`,
        ],
        volume: 1.0, //SoundController.sfxVolume.value,
      });
      SoundController[SOUND_LABELS.ACTION] = new Howl({
        src: [
          `${path}/assets/sounds/${SOUND_LABELS.ACTION}.ogg`,
          `${path}/assets/sounds/${SOUND_LABELS.ACTION}.mp3`,
        ],
        volume: SoundController.sfxVolume.value,
      });
      SoundController[SOUND_LABELS.EXPLODE] = new Howl({
        src: [
          `${path}/assets/sounds/${SOUND_LABELS.EXPLODE}.ogg`,
          `${path}/assets/sounds/${SOUND_LABELS.EXPLODE}.mp3`,
        ],
        volume: SoundController.sfxVolume.value,
      });
      SoundController[SOUND_LABELS.GATE] = new Howl({
        src: [
          `${path}/assets/sounds/${SOUND_LABELS.GATE}.ogg`,
          `${path}/assets/sounds/${SOUND_LABELS.GATE}.mp3`,
        ],
        volume: SoundController.sfxVolume.value,
      });

      SoundController[SOUND_LABELS.MENU] = new Howl({
        src: [
          `${path}/assets/sounds/${SOUND_LABELS.MENU}.ogg`,
          `${path}/assets/sounds/${SOUND_LABELS.MENU}.mp3`,
        ],
        volume: SoundController.sfxVolume.value,
        sprite: {
          1: [1110, 1000],
          2: [2460, 1000],
          3: [3960, 1000],
          4: [5214, 1000],
          5: [6520, 1000],
          6: [7789, 1000],
          7: [9079, 1000],
          8: [10385, 1000],
          9: [11651, 1000],
          10: [12917, 1000],
          11: [14179, 1000],
          12: [15499, 1000],
        },
      });
      resolve(true);
    });
  }

  static async playAudioSprite(label, key) {
    SoundController[label].play(key);
  }

  static async playAudio(label) {
    SoundController[label].stop();
    const isMain = label === SOUND_LABELS.MAIN || label === SOUND_LABELS.ABOUT;
    const volLevel =
      !SoundController.muted.value && isMain
        ? SoundController.volume.value
        : !SoundController.muted.value && !isMain
        ? SoundController.sfxVolume.value
        : 0.0;
    SoundController[label].volume(volLevel);
    SoundController[label].play();
    SoundController.currentLabel = label;
    if (label === SOUND_LABELS.MAIN || label === SOUND_LABELS.ABOUT) {
      SoundController.audio = SoundController[label];
    }
  }

  static async fadeFromTo(from, to) {
    if (SoundController.currentLabel === to) return;
    SoundController.playAudio(to);
    if (!SoundController.muted.value) {
      SoundController[from].fade(SoundController.volume.value, 0.0, 400);
      SoundController[to].fade(0.0, SoundController.volume.value, 600);
    }
  }

  static async musicOnOff(soundOn, useFade = true) {
    if (!SoundController.audio || SoundController.muted.value) return;
    return new Promise((resolve) => {
      const duration = useFade ? 1200 : 0;
      if (soundOn) {
        SoundController.audio.fade(SoundController.volume.value, 0.0, duration);
      } else {
        SoundController.audio.fade(0.0, SoundController.volume.value, duration);
      }
    });
  }

  static async mute(soundOn, useFade = true) {
    if (!SoundController.audio) return;

    if (soundOn) {
      SoundController.musicOnOff(soundOn, useFade);
      SoundController.muted.value = soundOn;
    } else {
      SoundController.muted.value = soundOn;
      SoundController.musicOnOff(soundOn, useFade);
    }
  }
}
