import { motion } from "framer-motion";
import styled from "styled-components";
import { setCenter, setCenterX, setSize, setVh } from "../../utils/styles/mixins";
import { colors, layout } from "../../utils/styles/theme";
import { mediaDesktop, mediaMobile } from "../../utils/styles/responsive";
import { rgba } from "polished";
import { EASE_FUNC } from "utils/styles/animations";

export const Wrapper = styled(motion.div)`
  width: 100%;
  height: calc(${setVh(100)});
  display: block;
  padding: ${layout.appSizes.margin2} ${layout.appSizes.margin}
    ${layout.appSizes.menuMobileHeight} ${layout.appSizes.margin};
  overflow: hidden;

  ${mediaDesktop(`
    width: calc(100% - ${layout.appSizes.menuDeskptopHeight});
    height: ${setVh(100)};
    display: flex;
    flex-direction: initial;
    justify-content: center;
    align-items: center;
    padding: 0;
  `)}

  ${mediaMobile(`
    &:before,
    &:after {
      opacity: 0;
      transition: opacity 0.8s 0.1s linear;
      pointer-events: none;
      position: fixed;
      left: 0;
      width: 100%;
      content: "";
      z-index: 1;
      pointer-events: none;
    }

    &:before {
      top: 0;
      height: 160rem;
       transform: rotate(354deg);
      background: linear-gradient(
        to bottom,
        ${colors.black} 0%,
        ${colors.black} 40%,
        transparent 100%
      );
    }
    &:after {
      bottom: 0;
      height: 120rem;
      background: linear-gradient(
        to top,
        ${colors.black} 0%,
        ${colors.black} 40%,
        transparent 100%
      );
    }

    &.show {
     &:before,
      &:after {
        opacity: 1;
      }}
  `)}
`;

export const Social = styled(motion.nav)`
  width: 100%;
  padding: 10rem 80rem 10rem 100rem;

  ${mediaMobile(`
    padding: 10rem 40rem;
  `)};
`;

export const SocialList = styled(motion.nav)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SocialItem = styled(motion.nav)`
  ${setSize("56rem", "56rem")};
  margin-right: 10px;
  opacity: 0;
  transition: opacity 0.4s 0.4s linear;

  ${mediaMobile(`
    ${setSize("44rem", "44rem")};
  `)};

  a {
    ${setSize("100%", "100%")};
    display: block;
  }
  svg {
    ${setSize("100%", "100%")};
    fill: none;
    stroke: #8300ff;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    pointer-events: none;

    .rect {
      stroke-dasharray: 20;
      transition: stroke-dasharray 0.6s ${EASE_FUNC.easeInOut};
    }
  }

  &.show {
    opacity: 1;
    svg {
      .rect {
        transition: stroke-dasharray 0.6s 4s ${EASE_FUNC.easeInOut};
        stroke-dasharray: 200;
      }
    }
  }
`;

export const MarqueeWrapper = styled(motion.div)`
  ${setSize("100vw", "120rem")};
  position: fixed;
  top: 60rem;
  transform: rotate(350deg);
  z-index: 2;

  > div {
    height: 100%;
  }

  ${mediaMobile(`
    top:-20rem;
    transform: rotate(354deg);
  `)}
`;

export const MarqueeText = styled.h4`
  width: 100%;
  font-size: 90rem;
  line-height: 4rem;
  font-family: MontserratBlack;
  color: ${colors.electricViolet};
  opacity: 1;
  -webkit-text-stroke: 2px ${colors.electricViolet};
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  letter-spacing: 0.05em;
  margin: 0 60rem;
  filter: drop-shadow(0 0 10rem ${colors.electricViolet});
  /* animation: glowAbout 10s infinite linear; */

  ${mediaMobile(`
    font-size: 26rem;
    margin: 0 15rem;
  `)}

  @keyframes glowAbout {
    0% {
      filter: drop-shadow(0 0 7rem ${rgba(colors.brightTurquoise, 0.55)});
    }
    50% {
      filter: drop-shadow(0 0 7rem ${rgba(colors.brightTurquoise, 0.9)});
    }
    100% {
      filter: drop-shadow(0 0 7rem ${rgba(colors.brightTurquoise, 0.55)});
    }
  }
`;

export const Content = styled.div`
  ${setSize("100%", "100%")};
  display: block;
  overflow-y: scroll;

  ${mediaDesktop(`
    ${setSize("calc(100% - 20vw)", setVh(70))};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: initial;
    overflow-y: initial;
    margin-top: 200rem;
  `)}
`;

export const ContentHUD = styled.svg`
  width: 100%;
  height: 100%;
  ${mediaDesktop(`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    // margin: 60rem 0;
  `)}

  

  .border {
    fill: none;
    stroke: ${colors.electricViolet};
    stroke-miterlimit: 10;
    stroke-width: 3px;
    fill: rgba(0, 0, 0, 0.3);

    ${mediaDesktop(`
      fill: rgba(0, 0, 0, 0.5);
    `)}
  }
  .stripe {
    fill: ${colors.electricViolet};
  }

  path {
    stroke-dasharray: 130;
    opacity: 0;
    transition: stroke-dasharray 0.6s ${EASE_FUNC.easeInOut},
      opacity 0.2s 0.4s linear;
  }
  polygon {
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  &.show {
    path {
      stroke-dasharray: 2400;
      opacity: 1;
      transition: stroke-dasharray 1.4s ${EASE_FUNC.easeInOut},
        opacity 0.2s 0.4s linear;
    }
    polygon {
      opacity: 1;
      &:nth-child(0) {
        transition: opacity 0.2s 0.6s linear;
      }
      &:nth-child(1) {
        transition: opacity 0.2s 0.7s linear;
      }
      &:nth-child(2) {
        transition: opacity 0.2s 0.8s linear;
      }
      &:nth-child(3) {
        transition: opacity 0.2s 0.9s linear;
      }
      &:nth-child(4) {
        transition: opacity 0.2s 1s linear;
      }
      &:nth-child(5) {
        transition: opacity 0.2s 1.1s linear;
      }
      &:nth-child(6) {
        transition: opacity 0.2s 1.2s linear;
      }
      &:nth-child(7) {
        transition: opacity 0.2s 1.3s linear;
      }
    }
  }
`;


export const ContentTextTitle = styled.h4`
  width: 100%;
  font-size: 48rem;
  line-height: 4rem;
  font-family: MontserratBlack;
  color: ${colors.electricViolet};
  opacity: 1;
  -webkit-text-stroke: 2px ${colors.electricViolet};
  -webkit-text-fill-color: rgba(0,0,0,0.6);
  text-align: center;
  margin-top: -12rem;
  letter-spacing: 0.05em;
`;
export const ContentTextDesc = styled.div`
  width: 100%;
  font-family: MontserratRegular;
  font-size: 16rem;
  line-height: 1.5;
  opacity: 1;
  text-shadow: 0 0 2px ${colors.electricViolet};
  padding: 0rem 100rem 10rem 60rem;
  text-align: left;

  /* @media (min-width: 1366px), (max-height: 901px) {
    font-size: 16rem;
  } */

  > span {
    /* font-family: MontserratBlack; */
    color: ${colors.brightTurquoise};
  }

  ${mediaDesktop(`
    font-size: 14rem;
  `)}
`;
export const ContentTextAwards = styled.ul`
  width: 100%;
  
  li {
    font-family: MontserratRegular;
    font-size: 12rem;
    line-height: 1.5;
    color: ${colors.brightTurquoise}
    
  }
    ${mediaDesktop(`
      li {
        font-size: 14rem;
      }
    `)}
`;

export const ContentText = styled(motion.div)`
  ${setCenter("absolute")};
  width: 100%;
  /* letter-spacing: 0.01em; */
  opacity: 1;

  ${mediaMobile(`
    top: 50%;

    ${ContentTextTitle} {
      font-size: 32rem;
      line-height: 4rem;
    }
    ${ContentTextDesc} {
      font-size: 12rem;
      padding: 0rem 40rem;
    }
  `)}
`;


export const ContentLeft = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 30rem;
  margin-top: 60rem;
  ${mediaDesktop(`
    width: 28vw;
    height: 100%;
    margin-top: 0rem;
    max-width: 430rem;
  `)}
`;
export const ContentRight = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  ${mediaDesktop(`
    width: 28vw;
    height: 100%;
    max-width: 430rem;
    ${ContentHUD} {
      transform: translateX(-50%) translateY(-50%) scaleX(-1);
    }
  `)}

  ${ContentTextDesc} {
    padding: 10rem 80rem 10rem 100rem;
  }
  ${mediaMobile(`
    margin-bottom: 120rem;
    ${ContentTextDesc} {
      padding: 10rem 40rem;
    }
  `)}
`;

export const ArrowDown = styled(motion.div)`
  ${setCenterX("fixed")};
  bottom: 65rem;
  z-index: ${layout.zIndex.ui};
  border: solid ${colors.brightTurquoise};
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 6rem;
  transform: translateX(-50%) rotate(45deg);
  animation: arrowMove 2s infinite linear;

  @keyframes arrowMove {
    0% {
      transform: translateX(-50%) translateY(0%) rotate(45deg) scale(1);
    }
    50% {
      transform: translateX(-50%) translateY(10%) rotate(45deg) scale(0.9);
    }
    100% {
      transform: translateX(-50%) translateY(0%) rotate(45deg) scale(1);
    }
  }
`;

