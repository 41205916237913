import styled from "styled-components";
import { mediaDesktop, mediaMobile } from "../../utils/styles/responsive";
import { colors } from "../../utils/styles/theme";
import { EASE_FUNC } from "../../utils/styles/animations";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const TextButton = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  fill: ${colors.white};
  opacity: 0;
  text {
    font: 22px Archivo;
    fill: ${colors.white};
  }
`;

export const NextButton = styled.button`
  width: 90rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: transform 0.4s 0.2s ${EASE_FUNC.easeOut};
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    cursor: default;
  }

  .next-bg {
    fill: ${colors.cherryPie};
    stroke: ${colors.electricViolet};
    stroke-miterlimit: 10;
    stroke-width: 2rem;
    stroke-dasharray: 110;
    opacity: 0;
    transition: stroke-dasharray 0.6s ${EASE_FUNC.easeInOut},
      fill 0.2s 0s linear, stroke 0.4s linear, opacity 0.4s 0.4s linear;
  }
  .next-line {
    fill: none;
    stroke: ${colors.brightTurquoise};
    stroke-miterlimit: 10;
    opacity: 0;
    stroke-width: 2rem;
    transform: translateX(0rem);
    transition: opacity 0.6s 0s ${EASE_FUNC.easeInOut},
      transform 0.4s ${EASE_FUNC.easeInOut};
  }

  ${mediaDesktop(`   
   .next-line {
      stroke-width: 5rem;
    }
   .next-bg {
      stroke-width: 5rem;
    }
    &:hover {
      &.show {
        transition: transform 0.4s ${EASE_FUNC.easeOut};
        transform: scale(0.9);
        .next-line {
          transform: translateX(10rem);
          transition: transform 0.4s 0.2s ${EASE_FUNC.easeOut};
        }
      }
    }
  `)}

  ${mediaMobile(`
    width: 60rem;
  `)}

  &.show {
    .next-bg {
      fill: ${colors.cherryPie};
      stroke: ${colors.cherryPie};
      stroke-dasharray: 390;
      opacity: 1;
      transition: stroke-dasharray 0.6s ${EASE_FUNC.easeInOut},
        fill 0.4s 0.3s linear, stroke 0.4s 0.6s linear, opacity 0.2s linear;
    }

    .next-line {
      opacity: 1;
      transform: translateX(0rem);
      transition: opacity 0.6s 0.4s ${EASE_FUNC.easeInOut},
        transform 0.4s ${EASE_FUNC.easeInOut};
    }

    ${TextButton} {
      opacity: 1;
      transition: opacity 0.6s 0.8s ${EASE_FUNC.easeInOut};
    }
  }
`;

export const PrevButton = styled.button`
  width: 90rem;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: transform 0.4s 0.2s ${EASE_FUNC.easeOut};
  cursor: pointer;
  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  .next-bg {
    fill: ${colors.cherryPie};
    stroke: ${colors.electricViolet};
    stroke-miterlimit: 10;
    stroke-width: 2rem;
    stroke-dasharray: 110;
    opacity: 0;
    transition: stroke-dasharray 0.6s ${EASE_FUNC.easeInOut},
      fill 0.2s 0s linear, stroke 0.4s linear, opacity 0.4s 0.4s linear;
  }
  .next-line {
    fill: none;
    stroke: ${colors.brightTurquoise};
    stroke-miterlimit: 10;
    stroke-width: 2rem;
    opacity: 0;
    transform: translateX(0rem);
    transition: opacity 0.6s 0s ${EASE_FUNC.easeInOut},
      transform 0.4s ${EASE_FUNC.easeInOut};
  }

  ${mediaDesktop(`   
    .next-line {
      stroke-width: 5rem;
    }
    .next-bg {
      stroke-width: 5rem;
    }
    &:hover {
      &.show {
        transition: transform 0.4s ${EASE_FUNC.easeOut};
        transform: scale(0.9);
        .next-line {
          transform: translateX(-10rem);
          transition: transform 0.4s 0.2s ${EASE_FUNC.easeOut};
        }
      }
    }
  `)}

  ${mediaMobile(`
    width: 60rem;
  `)}

  &.show {
    .next-bg {
      fill: ${colors.cherryPie};
      stroke: ${colors.cherryPie};
      stroke-dasharray: 390;
      opacity: 1;
      transition: stroke-dasharray 0.6s ${EASE_FUNC.easeInOut},
        fill 0.4s 0.3s linear, stroke 0.4s 0.6s linear, opacity 0.2s linear;
    }

    .next-line {
      opacity: 1;
      transform: translateX(0rem);
      transition: opacity 0.6s 0.4s ${EASE_FUNC.easeInOut},
        transform 0.4s ${EASE_FUNC.easeInOut};
    }

    ${TextButton} {
      opacity: 1;
      transition: opacity 0.6s 0.8s ${EASE_FUNC.easeInOut};
    }
  }
`;
