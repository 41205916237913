import styled from "styled-components";
import { colors, layout } from "utils/styles/theme";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${layout.zIndex.modals};
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24rem;
  font-family: Archivo;
  color: ${colors.electricViolet};
  text-align: center;

  p {
    max-width: 600rem;
  }

  h3 {
    font-size: 40rem;
    -webkit-text-stroke: 2px ${colors.electricViolet};
    -webkit-text-fill-color: transparent;
  }
`;
