import { motion } from "framer-motion";
import styled from "styled-components";
import { setCenterY, setVh } from "../../utils/styles/mixins";
import { colors, layout } from "utils/styles/theme";
import { mediaDesktop } from "utils/styles/responsive";

export const Wrapper = styled(motion.div)`
  width: 100vw;
  height: ${setVh(100)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  overflow: auto;
  padding: 40rem 20rem;
`;

export const ArrowRight = styled(motion.div)`
  ${setCenterY("fixed")};
  right: ${layout.appSizes.margin};
  z-index: ${layout.zIndex.ui};
  border: solid ${colors.brightTurquoise};
  border-width: 1px 1px 0 0;
  display: inline-block;
  padding: 6rem;
  transform: rotate(45deg);
  animation: arrowMoveR 2s infinite linear;
  opacity: 0;

  @keyframes arrowMoveR {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  ${mediaDesktop(`
    display: none;
  `)}
`;
export const ArrowLeft = styled(motion.div)`
  ${setCenterY("fixed")};
  left: ${layout.appSizes.margin};
  z-index: ${layout.zIndex.ui};
  border: solid ${colors.brightTurquoise};
  border-width: 0 0 1px 1px;
  display: inline-block;
  padding: 6rem;
  transform: rotate(45deg);
  animation: arrowMoveL 2s infinite linear;
  opacity: 0;

  @keyframes arrowMoveL {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  ${mediaDesktop(`
    display: none;
  `)}
`;
