import { Clock, Texture } from "three";

import { parseUrlQuery } from "../../utils/url";
import { Pane } from "tweakpane";
import { BaseScene } from "./generic/BaseScene";
import { Loader } from "./generic/loaders";
import { StandardScene } from "./generic/StandardScene";

import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { EventsManager } from "../events/EventsManager";
import { isBrowser, isDesktop, isMobile } from "../../utils/platform";

export class ScenesManager {
  static canvas: HTMLCanvasElement;
  static mainScene: BaseScene;
  static allCreated: boolean;
  static envMap: Texture;
  static tweakPane: any;
  static clock: Clock;
  static basePath = isBrowser() ? window.location.origin : "";
  static mouse = {
    x: 0,
    y: 0,
  };
  static windowHalfX = window.innerWidth / 2;
  static windowHalfY = window.innerHeight / 2;
  static followMouseEnabled = false;
  static inTransition = false;

  static assets: {
    head: GLTF;
    e2: GLTF;
    star: Texture;
    headGradient: Texture;
    background: Texture;
  };

  static loader = new Loader();

  static debugMode: boolean;
  static showControls = false;

  static setup = async (canvas: HTMLCanvasElement) => {
    if (ScenesManager.allCreated || !canvas) return;
    ScenesManager.debugMode = parseUrlQuery().debugScene === "true";
    ScenesManager.showControls = parseUrlQuery().showControls === "true";

    ScenesManager.loader.setup();
    EventsManager.setup();

    const [head, e2] = await ScenesManager.loader.loadModels([
      `${ScenesManager.basePath}/assets/models/head-tattoo.glb`,
      `${ScenesManager.basePath}/assets/models/box.glb`,
    ]);
    const [star, headGradient, background] =
      await ScenesManager.loader.loadTextures([
        `${ScenesManager.basePath}/assets/textures/star.png`,
        `${ScenesManager.basePath}/assets/textures/head-gradient.png`,
        `${ScenesManager.basePath}/assets/textures/background.jpg`,
      ]);
    ScenesManager.assets = {
      head: head,
      e2: e2,
      star: star,
      headGradient: headGradient,
      background: background,
    };

    ScenesManager.clock = new Clock();

    if (ScenesManager.showControls) {
      ScenesManager.tweakPane = new Pane();
    }

    ScenesManager.mainScene = new StandardScene(
      canvas,
      this.onStarted,
      this.onUpdate
    );

    if (isDesktop()) {
      window.addEventListener("pointermove", ScenesManager.onDocumentMouseMove);
    }
    window.addEventListener("resize", () => ScenesManager.resize());
  };

  static onDocumentMouseMove(event: { clientX: number; clientY: number }) {
    let ease = 0.001;

    if (isMobile()) {
      ease *= 4;
    }
    ScenesManager.mouse.x = -(event.clientX - ScenesManager.windowHalfX) * ease;
    ScenesManager.mouse.y = (event.clientY - ScenesManager.windowHalfY) * ease;
  }

  static resize() {
    ScenesManager.mainScene.resize();
    // ScenesManager.mainScene.composer?.setSize(width, window.innerHeight);

    // if (this.effectFocus) {
    //   this.effectFocus.uniforms["screenWidth"].value =
    //     width * window.devicePixelRatio;
    //   this.effectFocus.uniforms["screenHeight"].value =
    //     window.innerHeight * window.devicePixelRatio;
    // }
  }

  static onStarted = async () => {
    // console.log("onStarted");
  };

  static onUpdate = () => {
    ScenesManager.mainScene?.render();

    if (ScenesManager.followMouseEnabled) {
      ScenesManager.mainScene.camera.position.x +=
        (ScenesManager.mouse.x - ScenesManager.mainScene.camera.position.x) *
        0.05;
      ScenesManager.mainScene.camera.position.y +=
        (ScenesManager.mouse.y - ScenesManager.mainScene.camera.position.y) *
        0.05;
    } else {
      ScenesManager.mainScene.camera.position.set(0, 0, 6);
    }
    ScenesManager.mainScene.camera.lookAt(
      ScenesManager.mainScene.scene.position
    );
    // const delta = ScenesManager.clock.getDelta();
  };

  static dispose() {
    if (!ScenesManager.mainScene || !ScenesManager.mainScene.renderer) return;
    ScenesManager.mainScene?.stop();
    ScenesManager.mainScene?.renderer.setAnimationLoop(null);

    ScenesManager.clock?.stop();

    ScenesManager.allCreated = false;
  }
}
