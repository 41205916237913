import gsap from "gsap";
import {
  AdditiveBlending,
  Mesh,
  Object3D,
  ShaderMaterial,
  PlaneGeometry,
} from "three";
import { ScenesManager } from "../ScenesManager";
import { filmGrainFragment, filmGrainVertex } from "../shaders/filmGrain";
export default class FilmGrain extends Object3D {
  screen: Mesh | undefined;
  uniforms = {};
  material: ShaderMaterial | undefined;
  grainIntensity = 0.6;

  constructor() {
    super();

    this.build();
  }

  build() {
    this.material = this.getMaterial();
    const geometry = new PlaneGeometry(32, 18);
    this.screen = new Mesh(geometry, this.material);

    this.add(this.screen);
  }

  getMaterial() {
    this.uniforms = {
      tDiffuse: { type: "t", value: ScenesManager.assets.background },
      time: { type: "f", value: 0.0 },
      nIntensity: { type: "f", value: this.grainIntensity },
      sIntensity: { type: "f", value: this.grainIntensity },
      sCount: { type: "f", value: 2048 },
      grayscale: { type: "i", value: 0.5 },
    };

    return new ShaderMaterial({
      transparent: true,
      blending: AdditiveBlending,
      uniforms: this.uniforms,
      vertexShader: filmGrainVertex,
      fragmentShader: filmGrainFragment,
    });
  }

  hide(hasDelay: boolean) {
    if (this.screen) {
      gsap.to(this.screen.position, {
        z: 20.0,
        duration: 1,
        delay: hasDelay ? 0.6 : 0,
      });
    }
  }

  show() {
    if (this.screen) {
      gsap.to(this.screen.position, {
        z: 0.0,
        duration: 1.3,
        delay: 0.3,
      });
    }
  }

  animate() {
    if (this.material) {
      const time = performance.now() * 0.001;
      this.material.uniforms["time"].value = time;
    }
  }
}
