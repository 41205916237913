type panelOptions = {
  [key: string]: { x: number; y: number; z: number };
};

export const PANEL_CONFIG: panelOptions[] = [
  {
    2: { x: -15, y: 0, z: -15 },
    3: { x: 3, y: 3, z: -7 },
    7: { x: -17, y: 9, z: -15 },
    8: { x: -2.5, y: -5, z: 2 },
    9: { x: 3, y: 1, z: 1 },
    10: { x: 15, y: -3, z: -15 },
    14: { x: -2, y: 4, z: -4 },
  },
  {
    1: { x: -15, y: 0, z: -15 },
    3: { x: 3, y: 3, z: -5 },
    4: { x: -20, y: 9, z: -15 },
    7: { x: -4, y: -5, z: -4 },
    8: { x: 0, y: -6, z: 0.2 },
    9: { x: 3, y: 1, z: 1 },
    10: { x: 15, y: -3, z: -18 },
    11: { x: -3, y: 3.5, z: 0 },
    14: { x: -20, y: 8, z: -20 },
    15: { x: 17, y: -12, z: -23 },
  },
  {
    2: { x: -10, y: 6, z: -10 },
    3: { x: 4, y: 4, z: -1 },
    7: { x: -15, y: 0, z: -15 },
    8: { x: -15, y: -3, z: -12 },
    9: { x: 15, y: -3, z: -12 },
    10: { x: 15, y: 0, z: -15 },
    14: { x: 4, y: -4, z: -6 },
    15: { x: 0, y: -6, z: -4 },
  },
];

export const VIDEO_IDS = {
  ibm: "video-ibm",
  lcdp: "video-lcdp",
  ccxp: "video-ccxp",
  globo: "video-globo",
  wristbands: "video-wristbands",
  dreamworld: "video-dreamworld",
  fiatstrada: "video-fiat",
  beefeater: "video-beefeater",
  tiraonda: "video-tiraonda",
};
