import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import GlobalStyles from "./utils/styles/globalStyles";
import { ThemeProvider } from 'styled-components';
import { isBrowser } from './utils/platform';
import loadFonts from './utils/styles/fonts';
import { colors } from './utils/styles/theme';

const getPath = () => {
  return isBrowser() ? window.location.origin : '';
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <style
      dangerouslySetInnerHTML={{ __html: getPath() !== '' ? loadFonts(getPath()) : "" }}
    />
    <ThemeProvider theme={{ color: colors.cherryPie }}>
      <Router>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
