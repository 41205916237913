import React from 'react';

import * as Styled from './ButtonNavigationArrow.styles';
import { colors } from 'utils/styles/theme';

export interface ButtonNavigationArrowProps {
  onPrevAction: () => void;
  onNextAction: () => void;
  $isVisible: boolean;
}

const ButtonNavigationArrow: React.FC<ButtonNavigationArrowProps> = ({
  onPrevAction,
  onNextAction,
  $isVisible,
}) => {
  return (
    <Styled.Wrapper style={{ pointerEvents: $isVisible ? "all" : "none" }}>
      <Styled.PrevButton
        onClick={onPrevAction}
        className={$isVisible ? "show" : ""}
        disabled={!$isVisible}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.02 120.89">
          <g>
            <g>
              <path
                className="next-bg"
                d="M121,40.34,40.47,120.89,0,80.43V0H81.19Z"
              />
            </g>
            <g>
              <polyline
                className="next-line"
                points="38.72 65.19 23.02 80.89 38.6 96.47"
              />
            </g>
          </g>
        </svg>
        <Styled.TextButton
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 121.02 120.89"
        >
          <mask id="prevButtonMask">
            <path d="M121,40.34,40.47,120.89,0,80.43V0H81.19Z" />
          </mask>
          <text x="0" y="0" mask="url(#prevButtonMask)" fill={colors.white}>
            <animate
              attributeType="transform"
              attributeName="dx"
              from="100%"
              to="-50%"
              dur="4s"
              repeatCount="indefinite"
            />
            <tspan x="0" y="25%">
              PREV
            </tspan>
          </text>
        </Styled.TextButton>
      </Styled.PrevButton>
      <Styled.NextButton
        onClick={onNextAction}
        className={$isVisible ? "show" : ""}
        disabled={!$isVisible}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.02 120.89">
          <g>
            <g>
              <polygon
                className="next-bg"
                points="39.83 0 121.02 0 121.02 80.43 80.55 120.89 0 40.34 39.83 0"
              />
              <polyline
                className="next-line"
                points="82.3 65.19 98 80.89 82.43 96.47"
              />
            </g>
          </g>
        </svg>
        <Styled.TextButton
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 121.02 120.89"
        >
          <mask id="nextButtonMask">
            <path d="M39.83,0H121V80.43L80.55,120.89,0,40.34Z" />
          </mask>
          <text x="0" y="0" mask="url(#nextButtonMask)" fill={colors.white}>
            <animate
              attributeType="transform"
              attributeName="dx"
              from="-50%"
              to="100%"
              dur="4s"
              repeatCount="indefinite"
            />
            <tspan x="0" y="25%">
              NEXT
            </tspan>
          </text>
        </Styled.TextButton>
      </Styled.NextButton>
    </Styled.Wrapper>
  );
};

export default ButtonNavigationArrow;
