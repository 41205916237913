import { motion } from "framer-motion";
import styled from "styled-components";
import { setSize, setVh } from "../../utils/styles/mixins";
import { colors, layout } from "utils/styles/theme";
import { mediaDesktop, mediaMobile } from "utils/styles/responsive";
import { rgba } from "polished";

export const Wrapper = styled(motion.div)`
  width: 100vw;
  height: ${setVh(100)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  overflow: auto;
  padding: 40rem ${layout.appSizes.margin} 0rem ${layout.appSizes.margin};
  background-color: rgba(0, 0, 0, 0.8);

  &:before,
  &:after {
    opacity: 1;
    transition: opacity 0.8s 0.1s linear;
    pointer-events: none;
    position: fixed;
    left: 0;
    width: 120%;
    content: "";
    z-index: 1;
    pointer-events: none;
  }

  &:before {
    top: -100rem;
    left: -10%;
    height: ${setVh(47)};
    transform: rotate(354deg);
    background: linear-gradient(
      to bottom,
      ${colors.black} 0%,
      ${colors.black} 64%,
      transparent 100%
    );
  }
  &:after {
    bottom: 0;
    height: 80rem;
    background: linear-gradient(
      to top,
      ${colors.black} 0%,
      ${colors.black} 10%,
      transparent 100%
    );
  }

  ${mediaMobile(`
    padding: 40rem ${layout.appSizes.margin} 90rem ${layout.appSizes.margin};
    &:before,
    &:after {
      opacity: 1;
      transition: opacity 0.8s 0.1s linear;
      pointer-events: none;
      position: fixed;
      left: 0;
      width: 120%;
      content: "";
      z-index: 1;
      pointer-events: none;
    }

    &:before {      
      top: -20rem;
      left: -10%;
      height: ${setVh(38)};
      transform: rotate(354deg);
      background: linear-gradient(
        to bottom,
        ${colors.black} 0%,
        ${colors.black} 85%,
        transparent 90%
        transparent 100%
      );
    }
    &:after {
      bottom: 0;
      height: 210rem;
      background: linear-gradient(
        to top,
        ${colors.black} 0%,
        ${colors.black} 40%,
        transparent 100%
      );
    }
  `)}
`;

export const MarqueeText = styled.h4`
  width: 100%;
  font-size: 110rem;
  line-height: 4rem;
  font-family: MontserratBlack;
  color: ${colors.electricViolet};
  opacity: 1;
  -webkit-text-stroke: 4rem ${colors.electricViolet};
  -webkit-text-fill-color: transparent;
  text-align: center;
  letter-spacing: 0.05em;
  margin: 0 60rem;
  filter: drop-shadow(0 0 10rem white);
  animation: glow 4s infinite linear;

  ${mediaMobile(`
    font-size: 26rem;
    -webkit-text-stroke: 2rem ${colors.electricViolet};
    animation: glowMob 4s infinite linear;
  `)}

  @keyframes glow {
    0% {
      filter: drop-shadow(0 0 7rem ${rgba(colors.brightTurquoise, 0.55)});
    }
    50% {
      filter: drop-shadow(0 0 7rem ${rgba(colors.brightTurquoise, 0.7)});
    }
    100% {
      filter: drop-shadow(0 0 7rem ${rgba(colors.brightTurquoise, 0.55)});
    }
  }
  @keyframes glowMob {
    0% {
      filter: drop-shadow(0 0 4rem ${rgba(colors.brightTurquoise, 0.3)});
    }
    50% {
      filter: drop-shadow(0 0 4rem ${rgba(colors.brightTurquoise, 0.5)});
    }
    100% {
      filter: drop-shadow(0 0 4rem ${rgba(colors.brightTurquoise, 0.3)});
    }
  }
`;

export const MarqueeWrapper = styled(motion.div)`
  ${setSize("100vw", "120rem")};
  position: fixed;
  top: 90rem;
  transform: rotate(354deg);
  z-index: 2;
  opacity: 0.5;

  > div {
    height: 100%;
  }

  ${MarqueeText} {
    font-size: 80rem;
  }

  ${mediaMobile(`
    top: 40rem;
    transform: rotate(354deg);
    ${MarqueeText} {
      font-size: 36rem;
    }
  `)} 
`;

export const MarqueeWrapperReverse = styled(motion.div)`
  ${setSize("100vw", "120rem")};
  position: fixed;
  top: 170rem;
  transform: rotate(354deg);
  z-index: 2;

  > div {
    height: 100%;
  }

  ${MarqueeText} {
    font-size: 110rem;
  }

  ${mediaMobile(`
    top: 85rem;
    transform: rotate(354deg);   

    ${MarqueeText} {
      font-size: 60rem;
    }
  `)}
`;

export const ContentWrapper = styled(motion.div)`
  width: 100%;
  padding-top: ${setVh(20)};
  padding-bottom: 150rem;
  height: ${setVh(100)};
  overflow-y: scroll;

  ${mediaDesktop(`
    margin-left: -${layout.appSizes.menuDeskptopHeight};    
    padding: ${setVh(28)} 20% 130rem 20%;
  `)}
`;

export const ContentTitle = styled(motion.h2)`
  width: 100%;
  font-size: 28rem;
  line-height: 4rem;
  font-family: MontserratBlack;
  color: ${colors.brightTurquoise};
  opacity: 1;
  -webkit-text-stroke: 1px ${colors.brightTurquoise};
  -webkit-text-fill-color: transparent;
  text-align: left;
  letter-spacing: 0.05em;
  margin: 80rem auto 0 auto;
  ${mediaDesktop(`
    font-size: 48rem;
  `)}
`;

export const ContentText = styled(motion.p)`
  width: 100%;
  font-family: MontserratRegular;
  font-size: 14rem;
  line-height: 1.5;
  opacity: 1;
  text-align: left;
  margin: 30rem auto 0 auto;
  color: ${colors.white};

  > b {
    color: ${colors.brightTurquoise};
  }

  ${mediaDesktop(`
    font-size: 18rem;
    margin: 44rem auto 0 auto;
  `)}
`;

export const ContentImage = styled(motion.img)`
  width: 100%;
  height: auto;
  margin: 15rem auto 0 auto;
  border: 2px solid ${colors.electricViolet};
`;

export const ContentVideo = styled(motion.video)`
  width: 100%;
  height: auto;
  margin: 15rem auto 0 auto;
  border: 2px solid ${colors.electricViolet}
`;

