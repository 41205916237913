import React from 'react';

import * as Styled from './ButtonSound.styles';

export interface ButtonSoundProps {
  onAction: () => void;
  $isPlaying: boolean;
  $isVisible: boolean;
}

const ButtonSound: React.FC<ButtonSoundProps> = ({ onAction, $isPlaying, $isVisible }) => {
  return (
    <Styled.Wrapper
      onClick={onAction}
      className={$isVisible && $isPlaying ? "show playing" : $isVisible && !$isPlaying ? "show" : ''}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.42 50.61">
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_8" data-name="Camada 8">
            <path
              className="bg-fill"
              d="M.17.23S95-.28,94.47.23s2.38,50.38,0,50.38H26.72L0,23.89Z"
            />
            <rect
              className="bar-fill"
              x="30.72"
              y="16.65"
              width="2.81"
              height="17.87"
            />
            <rect
              className="bar-fill"
              x="46.55"
              y="16.65"
              width="2.81"
              height="17.87"
            />
            <rect
              className="bar-fill"
              x="38.64"
              y="16.65"
              width="2.81"
              height="17.87"
            />
            <rect
              className="bar-fill"
              x="54.72"
              y="16.65"
              width="2.81"
              height="17.87"
            />
            <rect
              className="bar-fill"
              x="62.89"
              y="16.65"
              width="2.81"
              height="17.87"
            />
            <rect
              className="bar-fill"
              x="71.06"
              y="16.65"
              width="2.81"
              height="17.87"
            />
          </g>
        </g>
      </svg>
    </Styled.Wrapper>
  );
};

export default ButtonSound;
