import React, { useEffect, useMemo, useRef, useState } from "react";

import { aboutMotionProps } from "../../utils/styles/animations";

import * as Styled from "./AboutPage.styles";
import Marquee from "react-fast-marquee";
import { APP_EVENTS, EventsManager } from "../../webgl/events/EventsManager";
import useGlobalStore from "utils/store";
import useWindowSize from "utils/hooks/useWindowSize";

const AboutPage: React.FunctionComponent = () => {
  const [show, setShow] = useState<boolean>(false);
  const { isLoading, showUI } =
    useGlobalStore();

const windowSize = useWindowSize(true);

const largeScreen = useMemo(() => windowSize.width > 1024, [windowSize.width]);
  const intersectContainerRef = useRef(null);
  const [isIntersectVisible, setIsIntersectVisible] = useState(false);

  const callbackFunction = (entries: any) => {
    const [entry] = entries;
    setIsIntersectVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });
    const refObject = intersectContainerRef.current;
    if (intersectContainerRef.current) {
      observer.observe(intersectContainerRef.current);
    }

    return () => {
      if (refObject) observer.unobserve(refObject);
    };
  }, [intersectContainerRef]);

  useEffect(() => {
    if (showUI && !isLoading) {
      setTimeout(() => {
        setShow(true);
      }, 1100);

      EventsManager.instance.on(APP_EVENTS.HEAD_HIDE, () => {
        setShow(false);
      });
    }

    return () => {
      setShow(false);
    };
  }, [isLoading, showUI]);

  
  return (
    <Styled.Wrapper {...aboutMotionProps} className={show ? "show" : ""}>
      {show && (
        <Styled.MarqueeWrapper
          {...aboutMotionProps}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <Marquee speed={largeScreen ? 150 : 80}>
            <Styled.MarqueeText>
              CAIO BASSETTI - CREATIVE DEVELOPER
            </Styled.MarqueeText>
            <Styled.MarqueeText>
              CAIO BASSETTI - CREATIVE DEVELOPER
            </Styled.MarqueeText>
          </Marquee>
        </Styled.MarqueeWrapper>
      )}
      <Styled.Content>
        <Styled.ContentLeft>
          {largeScreen ? (
            <Styled.ContentHUD
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 518 590.5"
              width="100%"
              height="100%"
              className={show ? "show" : ""}
            >
              <g>
                <g>
                  <path
                    className="border"
                    d="M57.99,0h459.54l-62,64.68v393.91l62.47,64v67.91H85L0,505.5V55.66L57.99,0Z"
                  />
                </g>
              </g>
            </Styled.ContentHUD>
          ) : (
            <Styled.ContentHUD
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 533.21 709.22"
              width="100%"
              height="100%"
              className={show ? "show" : ""}
            >
              <g>
                <g>
                  <path
                    className="border"
                    d="M.5,650.19V85.52l85-85H475.9l56.81,56.81V623.13l-85.59,85.59h-391Z"
                  />
                </g>
              </g>
            </Styled.ContentHUD>
          )}
          {show && (
            <Styled.ContentText
              {...aboutMotionProps}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <Styled.ContentTextDesc>
                I'm a <span>Creative Developer</span>, <span>Bass Player</span>,
                and <span>cat dad</span> specializing in{" "}
                <span>real-time 3D</span> experiences using <span>WebGL</span>,{" "}
                <span>GLSL Shaders</span>, and{" "}
                <span>Javascript (typescript)</span>.
                <br />
                <br />I started as a{" "}
                <span>Motion Designer and Flash Developer</span>, with over{" "}
                <span>14 years</span> of experience in the industry of creative
                development, I've acquired consistent skills to work with
                projects of the most varied types, such as 3D WebGL experiences
                and Augmented Reality (WebAR) to multiplayer interactive
                installations.
                <br />
                <br />I always seek projects to calm code-hungry mind,
                especially those <span>cutting-edge tech</span>,{" "}
                <span>high-level interactions</span>.
              </Styled.ContentTextDesc>
            </Styled.ContentText>
          )}
        </Styled.ContentLeft>
        <Styled.ContentRight ref={intersectContainerRef}>
          {largeScreen ? (
            <Styled.ContentHUD
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 518 590.5"
              width="100%"
              height="100%"
              className={show ? "show" : ""}
            >
              <g>
                <g>
                  <path
                    className="border"
                    d="M57.99,0h459.54l-62,64.68v393.91l62.47,64v67.91H85L0,505.5V55.66L57.99,0Z"
                  />
                </g>
              </g>
            </Styled.ContentHUD>
          ) : (
            <Styled.ContentHUD
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 533.21 709.22"
              width="100%"
              height="100%"
              className={show ? "show" : ""}
            >
              <g>
                <g>
                  <path
                    className="border"
                    d="M.5,650.19V85.52l85-85H475.9l56.81,56.81V623.13l-85.59,85.59h-391Z"
                  />
                </g>
              </g>
            </Styled.ContentHUD>
          )}
          {show && (
            <Styled.ContentText
              {...aboutMotionProps}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <Styled.ContentTextTitle></Styled.ContentTextTitle>
              <Styled.ContentTextDesc>
                In addition, I have good knowledge of <span>3D modeling</span>{" "}
                and <span>shading</span> to help 3D teams create optimized
                assets and achieve the <span>best performance</span>, and{" "}
                <span>visual quality</span> with assertive directions of the
                best export formats, use of materials, lighting, poly-count,
                etc.
                <br />
                <br />
                Awards
                <br />
                <Styled.ContentTextAwards>
                  <li>x6 FWA - FOTD</li>
                  <li>x4 Clio Awards</li>
                  <li>x3 Cannes Lions</li>
                  <li>x2 D&AD Awards</li>
                  <li>x1 Dubai Lynx</li>
                </Styled.ContentTextAwards>
              </Styled.ContentTextDesc>
              <Styled.Social>
                <Styled.SocialList>
                  <Styled.SocialItem className={show ? "show" : ""}>
                    <a
                      href="https://www.instagram.com/caiera.dev/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54.08 54.08"
                        width="100%"
                        height="100%"
                      >
                        <g>
                          <g>
                            <path d="M39.7,10.2a2.9,2.9,0,1,0,2.9,2.9,2.9,2.9,0,0,0-2.9-2.9Z" />
                            <path d="M27,14.7A12.3,12.3,0,1,0,39.3,27h0A12.29,12.29,0,0,0,27,14.7Zm0,20.2A7.9,7.9,0,1,1,34.9,27h0A7.85,7.85,0,0,1,27,34.9Z" />
                            <path
                              className="rect"
                              d="M52.08,52.08H2V2H52.08Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </a>
                  </Styled.SocialItem>
                  <Styled.SocialItem className={show ? "show" : ""}>
                    <a
                      href="https://www.linkedin.com/in/caio-bassetti/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54.08 54.08"
                        width="100%"
                        height="100%"
                      >
                        <g>
                          <g>
                            <path d="M15.88,23.15h4.77V38.47H15.88Zm2.39-7.62a2.76,2.76,0,1,1-2.77,2.76,2.77,2.77,0,0,1,2.77-2.76" />
                            <path d="M23.64,23.15h4.57v2.09h.06a5,5,0,0,1,4.51-2.47c4.83,0,5.72,3.17,5.72,7.29v8.41H33.74V31c0-1.78,0-4.06-2.48-4.06s-2.86,1.93-2.86,3.93v7.58H23.64Z" />
                            <path
                              className="rect"
                              d="M52.08,52.08H2V2H52.08Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </a>
                  </Styled.SocialItem>
                </Styled.SocialList>
              </Styled.Social>
            </Styled.ContentText>
          )}
        </Styled.ContentRight>
        {show && (
          <Styled.ArrowDown
            initial={{ opacity: 0 }}
            animate={{ opacity: !isIntersectVisible ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ delay: isIntersectVisible ? 0 : 3, duration: 0.6 }}
          />
        )}
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default AboutPage;
