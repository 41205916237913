export const ROUTES = {
  HOME: "/",
  PROJECT: "/project",
  ABOUT: "/about",
  NOT_FOUND: "/404",
} as const;

export enum Pages {
  index = "index",
  project = "project",
  about = "about",
  notFound = "notFound",
}
