export const delay = (sec: number) => {
  try {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(true);
      }, sec * 1000)
    );
  } catch (error) {
    console.error(error);
  }
};

// Uses h:m:s.ms format by default
// Uses h:m:s:f with `shouldUseFrames`
export const timecodeToSeconds = (
  timecode: string,
  shouldUseFrames = false,
  fps = 30
) => {
  const valuesArray = timecode
    .split(":")
    .map((number) => parseFloat(number.replace(",", ".")));
  const hours = valuesArray[0] * 60 ** 2;
  const minutes = valuesArray[1] * 60;
  const secs = valuesArray[2];
  const images = ((valuesArray[3] || 0) % fps) / fps;

  return hours + minutes + secs + (shouldUseFrames ? images : 0);
};

export const timecodeToMilliseconds = (
  timecode: string,
  shouldUseFrames?: boolean,
  fps?: number
) => timecodeToSeconds(timecode, shouldUseFrames, fps) * 1000;
